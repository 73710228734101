import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Components
import { HomepageImage } from "./homepage-image";
import { HomepageVideo } from "./homepage-video";

const HomepageTextContainer = styled.div`
  margin: 0 0 90px 0;
  padding: 0 30px;

  & .single-text-row {
    margin: 0 0 50px 0;

    @media (max-width: 1279px) {
      margin: 0 0 30px 0;
    }

    @media (max-width: 1279px) {
      margin: 0 0 30px 0;
    }

    & .text-hover-container {
      width: fit-content;
      margin: 0 auto;

      /* border: 1px solid red; */

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & + .gatsby-image-wrapper,
          & + video {
            opacity: 1;
          }
        }
      }
    }

    & object,
    & svg {
      display: block;
      margin: 0 auto;
      fill: #0043ea;
    }

    & img.svg-text {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 700px;

      @media (max-width: 1439px) {
        max-width: 620px;
      }

      @media (max-width: 1279px) {
        max-width: 370px;
      }
    }

    &.text-with-image {
      position: relative;

      &.position-left {
        & .gatsby-image-wrapper {
          left: 0;
        }
      }

      &.position-right {
        & .gatsby-image-wrapper {
          right: 0;
        }
      }

      & .gatsby-image-wrapper {
        position: absolute !important;
        top: -50%;
        transform: translateY(-35%);

        max-width: 20vw;
        max-height: 450px;

        opacity: 0;
        transition: 250ms opacity ease;

        & img {
          object-fit: contain;
        }

        @media (max-width: 1279px) {
          /* max-width: 10vw; */
          max-height: 300px;
        }

        @media (max-width: 768px) {
          max-width: 39vw;
        }
      }
    }

    &.text-with-video {
      position: relative;

      &.position-left {
        & video {
          left: 0;
        }
      }

      &.position-right {
        & video {
          right: 0;
        }
      }

      & video {
        position: absolute !important;
        top: -50%;
        transform: translateY(-35%);

        max-width: 20vw;
        max-height: 450px;

        opacity: 0;
        transition: 250ms opacity ease;

        & img {
          object-fit: contain;
        }

        @media (max-width: 1279px) {
          max-width: 39vw;
        }
      }
    }

    @media (hover: none) {
      & .gatsby-image-wrapper,
      & video {
        transform: translateY(-25%);
      }

      &.mobile-view-hidden {
        & .gatsby-image-wrapper,
        & video {
          opacity: 0;
        }
      }

      &.mobile-view-visible {
        & .gatsby-image-wrapper,
        & video {
          opacity: 1;
        }
      }

      &.position-left {
        & .gatsby-image-wrapper,
        & video {
          left: -30px !important;
        }
      }

      &.position-right {
        & .gatsby-image-wrapper,
        & video {
          right: -30px !important;
        }
      }
    }
  }
`;

export const HomepageText = () => {
  let isMobile = useMediaQuery("(hover: none)");

  const data = useStaticQuery(graphql`
    query {
      prismicHomepage {
        data {
          body {
            ... on PrismicHomepageDataBodyTextLine {
              id
              slice_type
              primary {
                text_svg {
                  url
                }
              }
            }
            ... on PrismicHomepageDataBodyTextLineWithImage {
              id
              slice_type
              primary {
                text_svg {
                  url
                }
                position
                image {
                  gatsbyImageData
                  alt
                }
              }
            }
            ... on PrismicHomepageDataBodyTextWithVideo {
              id
              slice_type
              primary {
                text_svg {
                  url
                }
                position
                video {
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.prismicHomepage.data.body.map((content, index) => {
    if (content.slice_type === `text_line`) {
      return (
        <div
          className="single-text-row text"
          key={`single_text_row_${index}_text`}
        >
          <img src={content.primary.text_svg.url} className="svg-text" />
        </div>
      );
    }

    if (content.slice_type === `text_line_with_image`) {
      return (
        <HomepageImage
          content={content}
          index={index}
          key={`single_text_row_${index}_text_with_image`}
          isMobile={isMobile}
        />
      );
    }

    if (content.slice_type === `text_with_video`) {
      return (
        <HomepageVideo
          content={content}
          index={index}
          key={`single_text_row_${index}_text_with_video`}
          isMobile={isMobile}
        />
      );
    }
  });

  return <HomepageTextContainer>{content}</HomepageTextContainer>;
};
