import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { use100vh } from "react-div-100vh";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-setup";

// Components
import { SignUp } from "../components/forms/signup-form";
import { ContactForm } from "../components/forms/contact-form";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-height: calc(${props => props.height}px - 78px);

  padding: 80px 30px;

  @media (max-width: 1279px) {
    padding: 125px 20px 150px 20px;
  }

  & .text-container {
    margin: 31px 0 0 0;

    & p {
      &:first-of-type {
        margin-top: 0;
      }

      &:lastst-of-type {
        margin-bottom: 0;
      }
    }

    @media (max-width: 768px) {
      margin: 20px 0 0 0;
    }
  }

  & .contact-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 90px;

    max-width: 1260px;
    margin: 0 auto;

    & .form-container {
      margin: 36px 0 0 0;

      & .mailchimp {
      }
    }

    @media (max-width: 1279px) {
      grid-column-gap: 50px;
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }
  }

  @media (max-width: 768px) {
    & .column {
      &.left {
        margin: 0 0 75px 0;
      }
    }
  }
`;

const Contact = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const height = use100vh();

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    setPageSetup({
      pageType: `contact`,
    });
  }, []);

  return (
    <Page height={height}>
      <div className="contact-container">
        <div className="column left center-text">
          <h1 className="glossy-display">GET IN TOUCH</h1>
          <div className="text-container">
            <PrismicRichText
              field={data.prismicContact.data.get_in_touch_text.richText}
            />
          </div>
          <div className="form-container">
            <ContactForm />
          </div>
        </div>

        <div className="column right center-text">
          <h1 className="glossy-display">THE NEWLETTER</h1>

          <div className="text-container">
            <PrismicRichText
              field={data.prismicContact.data.newsletter_signup_text.richText}
            />
          </div>

          <div className="form-container mailchimp">
            <SignUp pageType={`contact`} isNewsletter={false} />
          </div>
        </div>
      </div>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicContact {
      _previewable
      data {
        get_in_touch_text {
          richText
        }
        newsletter_signup_text {
          richText
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(Contact);
