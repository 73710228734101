import React from "react";
import { useInView } from "react-intersection-observer";

export const HomepageVideo = ({ content, isMobile }) => {
  const { ref, inView, entry } = useInView({
    rootMargin: "-35% 0px",
    threshold: 0,
  });

  return (
    <div
      className={`single-text-row text-with-video position-${content.primary.position.toLowerCase()} mobile-view-${
        inView && isMobile ? `visible` : `hidden`
      }`}
      ref={ref}
    >
      <div className="text-hover-container">
        <img src={content.primary.text_svg.url} className="svg-text" />
      </div>

      <video src={content.primary.video.url} loop autoPlay muted />
    </div>
  );
};
