import React, { useState, useEffect, useContext } from "react";
import { graphql, navigate, Link } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import nthCheck from "nth-check";
import { use100vh } from "react-div-100vh";
import { useLockBodyScroll, useToggle } from "react-use";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";

const Page = styled.div`
  padding: 150px 30px;
  min-height: calc(${props => props.height}px - 78px);

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: 1279px) {
    padding: 125px 30px 150px 30px;
  }

  & svg {
    & path {
      fill: ${props => props.color};
      transition: 300ms fill ease;
    }
  }

  & .projects-container {
    & .single-project {
      width: fit-content;
      max-width: 1180px;

      margin: 0 auto 100px auto;
      cursor: pointer;

      @media (max-width: 1279px) {
        /* max-width: 290px; */
        margin: 0 auto 42px auto;
        /* padding: 0 30px; */
      }

      & h1 {
        font-size: 100px;
        line-height: 120px;
        letter-spacing: 0.075em;
        text-align: center;

        @media (max-width: 1279px) {
          font-size: 60px;
          line-height: 78px;
        }

        @media (max-width: 768px) {
          font-size: 40px;
          line-height: 54px;
        }
      }

      &:last-of-type {
        margin: 0 auto;
      }
    }
  }
`;

const Work = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const [activeColorScheme, setActiveColorScheme] = useState({
    color: `#0043ea`,
  });

  const [hasBeenClicked, setHasBeenClicked] = useState(false);
  const [transitionText, setTransitionText] = useState(false);
  const [activeProjectTitle, setActiveProjectTitle] = useState({
    title: null,
    top: 0,
  });
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  const height = use100vh();

  useEffect(() => {
    setPageSetup({
      pageType: `work purple-background`,
    });
  }, []);

  const checkFirst = nthCheck("4n+1");
  const checkSecond = nthCheck("4n+2");
  const checkThird = nthCheck("4n+3");
  const checkFourth = nthCheck("4n+4");

  const determineColorScheme = index => {
    if (checkFirst(index)) {
      setActiveColorScheme({
        color: `#0043ea`,
      });
      setPageSetup({
        pageType: `work red-text blue-background`,
      });
    }

    if (checkSecond(index)) {
      setActiveColorScheme({
        color: `#0043EA`,
      });
      setPageSetup({
        pageType: `work orange-background`,
      });
    }

    if (checkThird(index)) {
      setActiveColorScheme({
        color: `#0043ea`,
      });
      setPageSetup({
        pageType: `work red-text yellow-background`,
      });
    }

    if (checkFourth(index)) {
      setActiveColorScheme({
        color: `#0043EA`,
      });
      setPageSetup({
        pageType: `work green-background`,
      });
    }
  };

  const transitionToPage = project => {
    setHasBeenClicked(true);
    toggleLocked();
    navigate(project.url);
  };

  const projects = data.prismicWork.data.projects
    .filter(project => project.project.document !== null)
    .map((project, index) => (
      <div
        key={`single_project_${index}_${project.project.document.id}`}
        id={`project-${project.project.document.id}`}
        className="single-project uppercase glossy-display"
        onMouseEnter={() => {
          determineColorScheme(index);
        }}
        onMouseLeave={() => {
          if (hasBeenClicked === false) {
            setPageSetup({
              pageType: `work purple-background`,
            });
            setActiveColorScheme({
              color: `#0043ea`,
            });
          }
        }}
        onClick={() => {
          determineColorScheme(index);
          transitionToPage(project.project.document);
        }}
      >
        <PrismicRichText field={project.project.document.data.title.richText} />
      </div>
    ));

  return (
    <>
      <Page
        bg={activeColorScheme.bg}
        color={activeColorScheme.color}
        height={height}
      >
        <section className="projects-container">{projects}</section>
      </Page>
    </>
  );
};

export const query = graphql`
  query {
    prismicWork {
      _previewable
      data {
        projects {
          project {
            document {
              ... on PrismicProject {
                id
                url
                data {
                  title {
                    text
                    richText
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(Work);
