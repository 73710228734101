import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Hooks
// import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Icons
import { LinkArrow } from "../components/icons/link-arrow";

// Context
import { PageSetup } from "../components/context/page-setup";
import { DropdownVideo } from "../components/video/dropdown-video";
import { PlyrVideo } from "../components/video/plyr-video";

const Page = styled.div`
  padding: 160px 30px;

  @media (max-width: 1279px) {
    padding: 125px 20px 150px 20px;
  }

  & .module {
    margin: 0 auto 60px auto;

    &:last-of-type {
      margin: 0 auto;
    }
  }

  & .title-container {
  }

  & .full-width-text-container {
    max-width: 915px;

    & .quote-text {
      & p {
        font-size: 18px;
        line-height: 23px;
        letter-spacing: 0.075em;

        margin: 0;
      }
    }
  }

  & .text-container {
    max-width: 1150px;

    & .text-layout-left {
      max-width: 680px;
    }

    & .text-layout-right {
      max-width: 565px;
      margin: 0 0 0 auto;
    }
  }

  & .quote-container {
    text-align: center;
    margin: 110px auto;
    max-width: 1150px;

    & h2 {
      font-size: 55px;
      line-height: 70px;
      letter-spacing: 0.075em;
    }
  }

  & .video-container {
    max-width: 915px;

    & h1 {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.075em;
      margin: 30px 0 0 0;
      text-transform: uppercase;

      @media (max-width: 1279px) {
        margin: 20px 0 0 0;
        font-size: 11.5px;
        line-height: 15px;
      }
    }

    & p {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .video-container,
  .dropdown-video-container {
    & .aspect-ratio-container {
      & .content {
        /* border: 3px solid #faf7eb; */
        /* inset: 1px; */
        /* inset: 3px; */

        /* & iframe {
          background-color: transparent !important;

          & .html5-video-player {
            background-color: transparent !important;
          }
        } */
      }
    }
  }

  & .dropdown-video-container {
    max-width: 1150px;

    & h1 {
      font-size: 18px;
      line-height: 23px;
      letter-spacing: 0.075em;
      margin: 30px 0 0 0;
      text-transform: uppercase;

      @media (max-width: 1279px) {
        margin: 20px 0 0 0;
        font-size: 11.5px;
        line-height: 15px;
      }
    }

    & p {
      &:first-of-type {
        margin-top: 0;
      }
    }

    & button.hover-arrow {
      padding: 5px 0;
      display: block;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & .stem {
            width: 80px;

            @media (max-width: 1279px) {
              width: 40px;
            }
          }
        }
      }
    }

    &:nth-of-type(3n + 1) {
      & .inner-video-container {
        max-width: 680px;
        margin: 0 0 60px 115px;

        @media (max-width: 1279px) {
          margin: 0 0 25px 115px;
        }

        @media (max-width: 600px) {
          margin: 0 0 25px 0;
        }
      }
    }

    &:nth-of-type(3n + 2) {
      & .inner-video-container {
        max-width: 560px;
        margin: 0 auto;
      }
    }

    &:nth-of-type(3n + 3) {
      & .inner-video-container {
        max-width: 680px;
        margin: 0 115px 60px 0;

        @media (max-width: 1279px) {
          margin: 0 115px 25px 0;
        }

        @media (max-width: 600px) {
          margin: 0 60px 25px 0;
        }
      }
    }

    & .dropdown {
      margin: 60px 0 120px 0;

      & p {
        margin: 1em 0;
      }

      @media (max-width: 1279px) {
        margin: 25px 0 60px 0;
      }
    }
  }

  & .link-container {
    max-width: 1150px;

    & .inner-link-container {
      & a {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;

        & .link-arrow-container {
          margin: 0 0 0 15px;

          @media (max-width: 1279px) {
            margin: 0;
          }
        }

        @media (max-width: 1279px) {
          max-width: 280px;
        }

        @media (max-width: 768px) {
          max-width: 230px;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & .stem {
            width: 80px;

            @media (max-width: 1279px) {
              width: 40px;
              border-width: 0.5em;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & .module {
      margin: 0 auto 20px auto;
    }

    & .title-container {
      margin: 0 auto 25px auto;
    }

    & .quote-container {
      max-width: 565px;
      margin: 40px auto;

      & h2 {
        font-size: 30px;
        line-height: 39px;
        letter-spacing: 0.075em;
      }
    }

    & .full-width-text-container {
      max-width: 565px;
    }

    & .text-container {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;

      & .text-layout-left {
        grid-column: 1 / 7;
        max-width: 565px;
      }

      & .text-layout-right {
        grid-column: 2 / 7;
        max-width: 565px;
      }

      & p {
        &:first-of-type {
          margin-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & .full-width-text-container {
      & .quote-text {
        & p {
          font-size: 11.5px;
          line-height: 15px;
        }
      }
    }
  }
`;

const TheFund = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  // let isDesktop = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    setPageSetup({
      pageType: `fund`,
    });
  }, []);

  const content = data.prismicTheFund.data.body.map((content, index) => {
    if (content.slice_type === `title`) {
      return (
        <div
          className="title-container center-text module glossy-display"
          key={`single_the_fund_title_container_${index}`}
        >
          <PrismicRichText field={content.primary.title_section.richText} />
        </div>
      );
    }

    if (content.slice_type === `full_width_text`) {
      return (
        <div
          className="full-width-text-container module"
          key={`single_the_fund_full_width_text_container_${index}`}
        >
          <PrismicRichText field={content.primary.text.richText} />

          <div className="quote-text uppercase">
            <PrismicRichText field={content.primary.quote_text.richText} />
          </div>
        </div>
      );
    }

    if (content.slice_type === `text`) {
      return (
        <div
          className="text-container module"
          key={`single_the_fund_text_container_${index}`}
        >
          <div
            className={`text-layout-${content.primary.text_position.toLowerCase()}`}
          >
            <PrismicRichText field={content.primary.text.richText} />
          </div>
        </div>
      );
    }

    if (content.slice_type === `quote`) {
      return (
        <div
          className="quote-container module glossy-display uppercase"
          key={`single_the_fund_quote_container_${index}`}
        >
          <PrismicRichText field={content.primary.quote.richText} />
        </div>
      );
    }

    if (content.slice_type === `link`) {
      return (
        <div
          className="link-container module"
          key={`single_the_fund_full_width_text_container_${index}`}
        >
          <div className="inner-link-container">
            <a
              href={content.primary.link.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PrismicRichText field={content.primary.link_text.richText} />
              <LinkArrow fill={`#0043ea`} />
            </a>
          </div>
        </div>
      );
    }

    if (content.slice_type === `video`) {
      // return null;
      return (
        <div
          className="video-container module"
          key={`single_the_fund_video_container_${index}`}
        >
          <PlyrVideo video={content.primary.video} />

          <PrismicRichText field={content.primary.video_title.richText} />
          <PrismicRichText field={content.primary.video_text.richText} />
        </div>
      );
    }

    if (content.slice_type === `dropdown_video`) {
      // return null;
      return (
        <div
          className="dropdown-video-container module"
          key={`single_the_fund_video_container_${index}`}
        >
          <DropdownVideo content={content} />
        </div>
      );
    }
  });

  return <Page>{content}</Page>;
};

export const query = graphql`
  {
    prismicTheFund {
      _previewable
      data {
        body {
          ... on PrismicTheFundDataBodyFullWidthText {
            id
            slice_type
            primary {
              text {
                richText
              }
              quote_text {
                richText
              }
            }
          }
          ... on PrismicTheFundDataBodyLink {
            id
            slice_type
            primary {
              link_text {
                richText
              }
              link {
                url
              }
            }
          }
          ... on PrismicTheFundDataBodyQuote {
            id
            slice_type
            primary {
              quote {
                richText
              }
            }
          }
          ... on PrismicTheFundDataBodyText {
            id
            slice_type
            primary {
              text_position
              text {
                richText
              }
            }
          }
          ... on PrismicTheFundDataBodyTitle {
            id
            slice_type
            primary {
              title_section {
                richText
              }
            }
          }
          ... on PrismicTheFundDataBodyVideo {
            id
            slice_type
            primary {
              video_title {
                richText
              }
              video {
                embed_url
                html
                height
                width
                provider_name
                prismicId
              }
              video_text {
                richText
              }
            }
          }
          ... on PrismicTheFundDataBodyDropdownVideo {
            id
            slice_type
            primary {
              video_title {
                richText
              }
              short_video_text {
                richText
              }
              video {
                embed_url
                height
                width
                html
                provider_name
                prismicId
              }
              video_text {
                richText
              }
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(TheFund);
