import React, { useState, useEffect, useContext } from "react";
import { graphql, navigate } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import { useMeasure } from "react-use";
import { use100vh } from "react-div-100vh";
import nthCheck from "nth-check";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-setup";

// Icons
import { CloseProject } from "../components/icons/close-project";
import { LinkArrow } from "../components/icons/link-arrow";

// Components
import { Video } from "../components/video/video";
import { NewsGallery } from "../components/images/news-gallery";

const Page = styled.div`
  min-height: ${props => props.height}px;

  &.active-section-title-page {
    opacity: ${props => props.titlePageOpacity};
    transition: 500ms opacity ease;
  }

  &.active-section-title-page,
  &.active-section-hook,
  &.active-section-information,
  &.active-section-press {
    display: grid;
    grid-template-rows: 90px auto 90px;
    height: 100%;
    min-height: unset;

    & section {
      height: 100%;
      min-height: calc(${props => props.height}px - 90px - 90px);
    }
  }

  &.active-section-trailer {
    display: grid;
    grid-template-rows: 90px auto 90px;
    height: 100%;
    min-height: ${props => props.height}px;

    align-items: center;

    & section {
      min-height: calc(${props => props.height}px - 90px - 90px);
    }
  }

  & .flex-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  & nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 16px 30px;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;

    z-index: 100;

    transition: 250ms opacity ease;
    opacity: ${props => props.opacity};

    & .nav-right {
      margin: 14px 0 0 0;

      & button {
        margin: 0;
        padding: 0;
      }
    }

    & button {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.075em;
      text-transform: uppercase;

      margin: 0 40px 0 0;
      padding: 0 0 1px 0;

      &.current {
        text-decoration: underline;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 4px;
        }
      }

      @media (max-width: 1279px) {
        font-size: 15px;
        line-height: 19px;
      }
    }

    @media (max-width: 1279px) {
      padding: 16px 20px;

      & .nav-left {
        & button {
          display: block;
          margin: 0 0 10px 0;
        }
      }

      & .nav-right {
        margin: 7px 0 0 0;

        & svg {
          width: 19px;
          height: auto;
        }
      }
    }
  }

  & section {
    min-height: calc(${props => props.height}px - 45px - 45px);

    &.trailer {
      display: flex;
      flex-direction: row;
      align-items: center;

      padding: 0 30px;

      @media (max-width: 1279px) {
        padding: 0 20px;
      }

      & .video-container {
        max-width: calc(
          (${props => props.height}px + 28.12%) -
            ${props => props.trailerTextHeight}px - 90px - 90px
        );
        width: 100%;
        width: min(
          100%,
          calc(
            (${props => props.height}px + 28.12%) -
              ${props => props.trailerTextHeight}px - 90px - 90px
          )
        );
        max-height: 100%;
        margin: 0 auto;

        & .text-container {
          max-width: 440px;

          & p {
            font-size: 15px;
            line-height: 19px;

            letter-spacing: 0.025em;

            @media (max-width: 1279px) {
              font-size: 13px;
              line-height: 16px;
              margin: 3px 0 0 0;
            }

            @media (max-width: 768px) {
              font-size: 9.5px;
              line-height: 12px;
              margin: 3px 0 0 0;
            }
          }
        }
      }
    }
  }

  & .title-page {
    padding: 0 30px;

    & h1 {
      font-size: 100px;
      line-height: 120px;
      text-align: center;

      max-width: 1180px;
      margin: 0 auto;

      @media (max-width: 1279px) {
        font-size: 40px;
        line-height: 60px;

        max-width: 1080px;
      }
    }
  }

  & .hook {
    padding: 0 30px;

    & h1 {
      font-size: 60px;
      line-height: 78px;
      text-align: center;

      max-width: 1180px;
      margin: 0 auto;

      @media (max-width: 1279px) {
        font-size: 35px;
        line-height: 45px;

        max-width: 1080px;
      }

      @media (max-width: 768px) {
        font-size: 22px;
        line-height: 29px;
      }
    }
  }

  & .information {
    & .text-container {
      & p {
        & strong {
          text-transform: uppercase;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 18px;
          }
        }
      }
    }
  }

  & .press {
    & .single-article-container {
      margin: 0 0 85px 0;

      &:last-of-type {
        margin: 0;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & .stem {
            width: 80px;

            @media (max-width: 1279px) {
              width: 40px;
              border-width: 0.035em;
            }
          }
        }
      }

      & .article-website {
        display: grid;
        grid-template-columns: auto 112px;
        grid-column-gap: 15px;
        align-items: center;

        width: fit-content;
        margin: 0 auto 5px auto;

        & p {
          display: inline;
          width: fit-content;
          margin: 0;

          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.075em;
        }

        @media (max-width: 1279px) {
          & p {
            font-size: 16px;
            line-height: 20px;
          }
        }

        @media (max-width: 768px) {
          grid-template-columns: auto 50px;

          & p {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
      }

      & .article-text {
        max-width: 1130px;

        & p {
          font-size: 40px;
          line-height: 52px;
          letter-spacing: 0.075em;

          text-transform: uppercase;
          margin: 0;
        }

        @media (max-width: 1439px) {
          max-width: 600px;

          & p {
            font-size: 35px;
            line-height: 45px;
          }
        }

        @media (max-width: 768px) {
          max-width: 335px;

          & p {
            font-size: 22px;
            line-height: 29px;
          }
        }
      }
    }

    @media (max-width: 1279px) {
      width: 100%;

      &.flex-center {
        display: block;
        flex-direction: unset;
        flex-wrap: unset;
        align-items: unset;
        justify-content: unset;
      }

      & .articles-container {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 100%;
        /* max-width: 335px; */
        margin: 0 auto;

        & .remove-scroll {
          position: relative;
          width: 100%;
          height: 100%;
        }

        & .single-article-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  & .images {
    /* padding: 0 0 105px 0; */
    padding: 105px 0;

    & .image-outer-container {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 90px;

      & .single-image-container {
        &:nth-of-type(5n + 1) {
          grid-column: 1 / 7;

          & .caption-container {
            padding: 0 0 0 30px;
          }
        }
        &:nth-of-type(5n + 2) {
          grid-column: 8 / 12;
          margin: 320px 0 0 0;
        }
        &:nth-of-type(5n + 3) {
          grid-column: 2 / 6;
        }
        &:nth-of-type(5n + 4) {
          grid-column: 7 / 13;
          margin: 290px 0 0 0;
        }

        &:nth-of-type(5n + 5) {
          grid-column: 4 / 8;
          margin: 0;
        }

        & .caption-container {
          max-width: 440px;

          & p {
            font-size: 15px;
            line-height: 19px;
            letter-spacing: 0.025em;

            margin: 10px 0 0 0;
          }
        }
      }
    }

    @media (max-width: 1279px) {
      margin: 0;
      padding: 65px 0 105px 0;

      & .image-outer-container {
        grid-template-columns: repeat(6, 1fr);
        grid-row-gap: 40px;

        & .single-image-container {
          /* &:nth-of-type(5n + 1) {
            grid-column: 1 / 6;

            & .caption-container {
              padding: 0 20px;
            }
          }

          &:nth-of-type(5n + 2) {
            grid-column: 3 / 7;
            margin: 0;
          }

          &:nth-of-type(5n + 3) {
            grid-column: 1 / 4;

            & .caption-container {
              padding: 0 20px;
            }
          }

          &:nth-of-type(5n + 4) {
            grid-column: 2 / 7;
            margin: 0;
          }

          &:nth-of-type(5n + 5) {
            grid-column: 3 / 7;
            margin: 0;
          } */

          &:nth-of-type(5n + 1) {
            grid-column: 4 / 7;
            margin: 0;

            & .caption-container {
              padding: 0;
            }
          }
          &:nth-of-type(5n + 2) {
            grid-column: 1 / 5;
            margin: 0;

            & .caption-container {
              padding: 0 0 0 20px;
            }
          }

          &:nth-of-type(5n + 3) {
            grid-column: 2 / 6;
            margin: 0;

            & .caption-container {
              padding: 0;
            }
          }
          &:nth-of-type(5n + 4) {
            grid-column: 4 / 7;
            margin: 0;
          }
          &:nth-of-type(5n + 5) {
            grid-column: 1 / 5;

            margin: 0;
            & .caption-container {
              padding: 0 0 0 20px;
            }
          }

          & .caption-container {
            /* max-width: 440px; */

            & p {
              font-size: 9.5px;
              line-height: 12px;
              letter-spacing: 0.025em;

              margin: 3px 0 0 0;
            }
          }
        }
      }
    }
  }

  & .information {
    & .text-container {
      & strong {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.075em;
      }
    }
  }

  @media (max-width: 1279px) {
    &.active-section-press {
      display: block;
      grid-template-rows: unset;
      height: ${props => props.height}px;

      & section {
        height: ${props => props.height}px;
      }
    }
  }
`;

const TrailerTextContainer = ({ text, setTrailerTextHeight }) => {
  const [ref, { x, y, width, height, top, right, bottom, left }] = useMeasure();

  useEffect(() => {
    setTrailerTextHeight(height);
  }, [height, setTrailerTextHeight]);

  return (
    <div className="text-container" ref={ref}>
      <PrismicRichText field={text} />
    </div>
  );
};

const Project = ({ data, pageContext }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const height = use100vh();
  const [trailerTextHeight, setTrailerTextHeight] = useState(50);
  const [navOpacity, setNavOpacity] = useState(1);

  const [activeColorScheme, setActiveColorScheme] = useState({
    color: `#0043ea`,
  });

  const [activeSection, setActiveSection] = useState(`title-page`);
  const [hasRunOnce, setHasRunOnce] = useState(false);

  const [titlePageOpacity, setTitlePageOpacity] = useState(0);

  useEffect(() => {
    const triggerTitleFadeIn = setTimeout(() => {
      setTitlePageOpacity(1);
    }, 500);
    return () => clearTimeout(triggerTitleFadeIn);
  }, []);

  useEffect(() => {
    if (activeSection === `title-page` && hasRunOnce === false) {
      if (data.prismicProject.data.hook !== null) {
        if (data.prismicProject.data.hook.text.length >= 1) {
          const triggerNewSection = setTimeout(() => {
            setActiveSection(`hook`);
          }, 3500);
          return () => clearTimeout(triggerNewSection);
        }
      }
    }
  }, [hasRunOnce, data]);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1280px)");

  const checkFirst = nthCheck("4n+1");
  const checkSecond = nthCheck("4n+2");
  const checkThird = nthCheck("4n+3");
  const checkFourth = nthCheck("4n+4");

  useEffect(() => {
    if (pageContext.type === `project`) {
      if (checkFirst(pageContext.positionInArray)) {
        setActiveColorScheme({
          color: `#0043ea`,
        });
        setPageSetup({
          pageType: `project red-text blue-background active-section-${activeSection}`,
        });
      } else if (checkSecond(pageContext.positionInArray)) {
        setActiveColorScheme({
          color: `#0043EA`,
        });
        setPageSetup({
          pageType: `project blue-text orange-background active-section-${activeSection}`,
        });
      } else if (checkThird(pageContext.positionInArray)) {
        setActiveColorScheme({
          color: `#0043ea`,
        });
        setPageSetup({
          pageType: `project red-text yellow-background active-section-${activeSection}`,
        });
      } else if (checkFourth(pageContext.positionInArray)) {
        setActiveColorScheme({
          color: `#0043EA`,
        });
        setPageSetup({
          pageType: `project blue-text green-background active-section-${activeSection}`,
        });
      } else {
        setActiveColorScheme({
          color: `#0043EA`,
        });
        setPageSetup({
          pageType: `project blue-text green-background active-section-${activeSection}`,
        });
      }
    }
  }, [pageContext, activeSection]);

  const images = data.prismicProject.data.images.map((image, index) => (
    <div className="single-image-container" key={`single_image_${index}`}>
      <GatsbyImage
        image={image.image.gatsbyImageData}
        alt={image.image.alt !== null ? image.image.alt : ``}
      />

      <div className="caption-container">
        <PrismicRichText field={image.caption.richText} />
      </div>
    </div>
  ));

  const articles = data.prismicProject.data.articles
    .filter((article, index) => index <= 2)
    .map((article, index) => (
      <div
        className="single-article-container center-text"
        key={`single_article_${index}`}
      >
        <a
          href={article.article_link.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="article-website uppercase">
            <PrismicRichText field={article.article_website.richText} />
            <LinkArrow fill={activeColorScheme.color} />
          </div>
          <div className="article-text glossy-display">
            <PrismicRichText field={article.article_text.richText} />
          </div>
        </a>
      </div>
    ));

  return (
    <Page
      height={height}
      trailerTextHeight={trailerTextHeight}
      className={`active-section-${activeSection}`}
      color={activeColorScheme.color}
      titlePageOpacity={titlePageOpacity}
      opacity={navOpacity}
    >
      <nav>
        <div className="nav-left">
          {data.prismicProject.data.hook !== null && (
            <>
              {data.prismicProject.data.hook.text.length >= 1 && (
                <button
                  onClick={() => {
                    setActiveSection(`hook`);
                    setHasRunOnce(true);
                  }}
                  className={activeSection === `hook` ? `current` : ``}
                >
                  Hook
                </button>
              )}
            </>
          )}

          {data.prismicProject.data.text !== null && (
            <button
              onClick={() => {
                setActiveSection(`information`);
                setHasRunOnce(true);
              }}
              className={activeSection === `information` ? `current` : ``}
            >
              Information
            </button>
          )}

          {articles.length >= 1 && (
            <button
              onClick={() => {
                setActiveSection(`press`);
                setHasRunOnce(true);
              }}
              className={activeSection === `press` ? `current` : ``}
            >
              Press
            </button>
          )}

          {images.length >= 1 && (
            <button
              onClick={() => {
                setActiveSection(`images`);
                setHasRunOnce(true);
              }}
              className={activeSection === `images` ? `current` : ``}
            >
              Images
            </button>
          )}

          {data.prismicProject.data.trailer !== null && (
            <button
              onClick={() => {
                setActiveSection(`trailer`);
                setHasRunOnce(true);
              }}
              className={activeSection === `trailer` ? `current` : ``}
            >
              Trailer
            </button>
          )}
        </div>

        <div className="nav-right">
          <button
            onClick={() => {
              setNavOpacity(0);

              const fadeOutNav = setTimeout(() => {
                navigate(`/work/`);
              }, 300);
              return () => clearTimeout(fadeOutNav);
            }}
            aria-label="Work page"
          >
            <CloseProject />
          </button>
        </div>
      </nav>

      <div className="spacer" />

      {activeSection === `title-page` && (
        <>
          <section className="title-page flex-center uppercase glossy-display">
            {data.prismicProject.data.title !== null && (
              <PrismicRichText
                field={data.prismicProject.data.title.richText}
              />
            )}
          </section>
        </>
      )}

      {activeSection === `hook` && (
        <section className="hook flex-center uppercase glossy-display">
          {data.prismicProject.data.hook !== null && (
            <PrismicRichText field={data.prismicProject.data.hook.richText} />
          )}
        </section>
      )}

      {activeSection === `information` && (
        <section className="information center-text flex-center">
          <div className="text-container">
            {data.prismicProject.data.text !== null && (
              <PrismicRichText field={data.prismicProject.data.text.richText} />
            )}
          </div>
        </section>
      )}

      {activeSection === `press` && (
        <section className="press flex-center">
          <div className="articles-container">
            {isDesktop ? (
              <>{articles}</>
            ) : (
              <>{<NewsGallery articles={articles} />}</>
            )}
          </div>
        </section>
      )}

      {activeSection === `images` && (
        <section className="images">
          <div className="image-outer-container">{images}</div>
        </section>
      )}

      {activeSection === `trailer` && (
        <section className="trailer">
          <div className="video-container">
            <Video
              url={data.prismicProject.data.trailer.embed_url}
              height={data.prismicProject.data.trailer.height}
              width={data.prismicProject.data.trailer.width}
            />

            {data.prismicProject.data.caption !== null && (
              <TrailerTextContainer
                text={data.prismicProject.data.caption.richText}
                setTrailerTextHeight={setTrailerTextHeight}
              />
            )}
          </div>
        </section>
      )}

      <div className="spacer" />
    </Page>
  );
};

export const query = graphql`
  query Project($uid: String) {
    prismicProject(uid: { eq: $uid }) {
      _previewable
      data {
        title {
          richText
        }
        hook {
          richText
          text
        }
        text {
          richText
          text
        }
        articles {
          article_text {
            richText
          }
          article_website {
            richText
          }
          article_link {
            url
          }
        }
        images {
          image {
            alt
            gatsbyImageData
          }
          caption {
            richText
          }
        }
        caption {
          richText
        }
        trailer {
          embed_url
          height
          width
          id
        }
        title {
          text
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(Project);
