import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 100;

    padding: 25px 30px;

    display: grid;
    grid-template-columns: auto 90px;
    grid-column-gap: 20px;
    align-items: center;

    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.025em;
    background-color: #dcede9;

    @media (max-width: 1100px) {
      align-items: flex-start;
    }

    @media (max-width: 1279px) {
      padding: 15px 20px;

      font-size: 11px;
      line-height: 14px;

      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
    }

    & .cookie-text {
      max-width: 670px;

      @media (max-width: 1279px) {
        max-width: 500px;
        margin: 0 0 20px 0;
      }
    }

    & .cookie-container {
      display: inline-block;

      @media (max-width: 1279px) {
        margin: 0 10px 0 0;
      }
    }

    & .content-container {
    }

    & .cookie-text > p {
      margin: 0;
      color: #0043ea;
    }

    & .cookie-button {
      border: 1px solid #0043ea;

      text-align: center;
      text-transform: uppercase;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.075em;

      padding: 3px 10px;

      transition: 250ms all ease;
      color: #0043ea;

      order: 1;

      cursor: pointer;

      @media (max-width: 1279px) {
        font-size: 10px;
        line-height: 13px;
        height: 20px;

        padding: 0 8px;
        margin: 0;

        border-width: 0.07em;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #dcede9;
          background-color: #0043ea;
        }
      }
    }

    & a.privacy-link {
      border: 1px solid #0043ea;
      transition: 250ms all ease;

      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.075em;
      padding: 3px 10px;

      transition: 250ms all ease;

      color: #0043ea;
      cursor: pointer;
      display: inline-block;

      @media (max-width: 1279px) {
        font-size: 10px;
        padding: 2px 5px;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #dcede9;
          background-color: #0043ea;
        }
      }
    }
  }
`;

export const Cookies = ({ location, color, bgColor }) => {
  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          cookie_text {
            html
          }
        }
      }
    }
  `);

  return (
    <CookieContainer>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="new-name-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div
          className="cookie-text"
          dangerouslySetInnerHTML={{
            __html: data.prismicHomepage.data.cookie_text.html,
          }}
        />
      </CookieConsent>
    </CookieContainer>
  );
};

// import React from "react";
// import { useStaticQuery, graphql, Link } from "gatsby";
// import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
// import CookieConsent from "react-cookie-consent";
// import styled from "styled-components";

// const CookieContainer = styled.div`
//   position: fixed;
//   bottom: 0;
//   left: 0;
//   right: 0;

//   z-index: 100;

//   padding: 25px 30px;

//   display: grid;
//   grid-template-columns: auto 90px 213px;
//   grid-column-gap: 20px;
//   align-items: center;

//   font-size: 18px;
//   line-height: 22px;
//   letter-spacing: 0.025em;
//   background-color: #dcede9;

//   @media (max-width: 1100px) {
//     align-items: flex-start;
//   }

//   @media (max-width: 1279px) {
//     padding: 15px 20px;

//     font-size: 11px;
//     line-height: 14px;

//     display: block;
//     grid-template-columns: unset;
//     grid-column-gap: unset;
//   }

//   & .cookie-text {
//     max-width: 670px;

//     @media (max-width: 1279px) {
//       max-width: 500px;
//       margin: 0 0 20px 0;
//     }
//   }

//   & .cookie-container {
//     display: inline-block;

//     @media (max-width: 1279px) {
//       margin: 0 10px 0 0;
//     }
//   }

//   & .content-container {
//   }

//   & .cookie-text > p {
//     margin: 0;
//     color: #0043ea;
//   }

//   & .cookie-button {
//     border: 1px solid #0043ea;

//     text-align: center;
//     text-transform: uppercase;

//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: 0.075em;

//     padding: 3px 10px;

//     transition: 250ms all ease;
//     color: #0043ea;

//     order: 1;

//     cursor: pointer;

//     @media (max-width: 1279px) {
//       font-size: 10px;
//       padding: 2px 5px;
//     }

//     @media (hover: hover) and (pointer: fine) {
//       &:hover,
//       &:focus,
//       &:focus-within {
//         color: #dcede9;
//         background-color: #0043ea;
//       }
//     }
//   }

//   & a.privacy-link {
//     border: 1px solid #0043ea;
//     transition: 250ms all ease;

//     text-align: center;
//     text-transform: uppercase;
//     font-size: 16px;
//     line-height: 22px;
//     letter-spacing: 0.075em;
//     padding: 3px 10px;

//     transition: 250ms all ease;

//     color: #0043ea;
//     cursor: pointer;
//     display: inline-block;

//     @media (max-width: 1279px) {
//       font-size: 10px;
//       padding: 2px 5px;
//     }

//     @media (hover: hover) and (pointer: fine) {
//       &:hover,
//       &:focus,
//       &:focus-within {
//         color: #dcede9;
//         background-color: #0043ea;
//       }
//     }
//   }
// `;

// export const Cookies = ({ location, color, bgColor }) => {
//   const data = useStaticQuery(graphql`
//     {
//       prismicHomepage {
//         data {
//           cookie_text {
//             html
//           }
//         }
//       }
//     }
//   `);

//   return (
//     <CookieContainer>
//       <div
//         className="cookie-text"
//         dangerouslySetInnerHTML={{
//           __html: data.prismicHomepage.data.cookie_text.html,
//         }}
//       />

//       <CookieConsent
//         location="bottom"
//         buttonText="Accept"
//         cookieName="new-name-gdpr-google-analytics"
//         containerClasses="cookie-container"
//         contentClasses="content-container"
//         buttonWrapperClasses="button-container"
//         buttonClasses="cookie-button"
//         disableStyles={true}
//         onAccept={() => {
//           initializeAndTrack(location);
//         }}
//         expires={150}
//       ></CookieConsent>

//       <Link to={`/privacy-policy/`} className="privacy-link">
//         VIEW PRIVACY POLICY
//       </Link>
//     </CookieContainer>
//   );
// };
