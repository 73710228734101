import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { RemoveScroll } from "react-remove-scroll";

// Icons
import { SplashLogo } from "../icons/splash-logo";

const SplashContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  z-index: 10000;

  transition: 300ms background-color ease-in, 500ms opacity ease;
  background-color: ${props => props.bgColor};

  cursor: pointer;

  & svg {
    position: fixed;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);

    width: 75vw;

    transition: 300ms all linear;
    z-index: 100;

    & path {
      fill: ${props => props.color};
      transition: 300ms fill ease-in;
    }

    @media (max-width: 600px) {
      width: 80vw;
    }
  }

  &.logo-size-small {
    & svg {
      width: 215px;
      top: 28px;
      right: 30px;
      transform: translate(0%, 0%);

      @media (max-width: 500px) {
        width: 160px;
        top: 20px;
        right: 20px;
      }
    }
  }

  &.hide-splash {
    opacity: 0;
  }
`;

export const Splash = ({
  setIsSplashOpen,
  setHideSplash,
  hideSplash,
  closeSplash,
}) => {
  const colorSchemes = [
    { color: `#0043EA`, background: `#5FAE9C` },
    { color: `#0043EA`, background: `#D9CA72` },
    { color: `#0043EA`, background: `#9DBBDC` },
    { color: `#0043EA`, background: `#f2c8ba` },
    { color: `#0043EA`, background: `#d6cbfb` },
  ];

  const [activeColorScheme, setActiveColorScheme] = useState(colorSchemes[0]);
  const [logoSize, setLogoSize] = useState(`large`);

  useEffect(() => {
    const triggerTimer = setTimeout(() => {
      const colorSchemeOne = setTimeout(() => {
        setActiveColorScheme(colorSchemes[1]);

        const colorSchemeTwo = setTimeout(() => {
          setActiveColorScheme(colorSchemes[2]);

          const colorSchemeThree = setTimeout(() => {
            setActiveColorScheme(colorSchemes[3]);

            const colorSchemeFour = setTimeout(() => {
              setActiveColorScheme(colorSchemes[4]);

              const shrinkLogo = setTimeout(() => {
                setLogoSize(`small`);

                const fadeOutBackground = setTimeout(() => {
                  setHideSplash(true);

                  const hideSplash = setTimeout(() => {
                    setIsSplashOpen(false);
                  }, 100);
                  return () => clearTimeout(hideSplash);
                }, 800);
                return () => clearTimeout(fadeOutBackground);
              }, 600);
              return () => clearTimeout(shrinkLogo);
            }, 600);

            return () => clearTimeout(colorSchemeFour);
          }, 600);

          return () => clearTimeout(colorSchemeThree);
        }, 600);

        return () => clearTimeout(colorSchemeTwo);
      }, 600);
      return () => clearTimeout(colorSchemeOne);
    }, 1500);
    return () => clearTimeout(triggerTimer);
  }, []);

  return (
    <RemoveScroll>
      <SplashContainer
        color={activeColorScheme.color}
        bgColor={activeColorScheme.background}
        className={`logo-size-${logoSize} ${
          hideSplash ? `hide-splash` : `show-splash`
        }`}
        onClick={() => closeSplash()}
      >
        <SplashLogo />
      </SplashContainer>
    </RemoveScroll>
  );
};
