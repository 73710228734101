import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";

// Utils
import { encodeForm } from "../utils/encode-form";

const ContactFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  @media (max-width: 1279px) {
    width: 100%;
  }

  & .signup-text {
    margin: 0 0 28px 0;

    & p {
      margin: 0;
    }
  }

  & .thank-you-message,
  & form {
    margin: 0;

    & .grid {
      &.grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;

        @media (max-width: 1279px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;
        }
      }
    }

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #0043ea;

      margin: 0 0 15px 0;

      @media (max-width: 1279px) {
        border-width: 0.05em;
      }

      &.textarea {
        border-bottom: 0;

        & label {
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
      }

      & input,
      & textarea {
        width: 100%;
        padding: 0;
        margin: 0;
        border: 0;
        border-radius: 0;

        background-color: transparent;
        appearance: none;

        font-size: 22px;
        line-height: 28px;
        letter-spacing: 0.025em;

        color: #0043ea;

        @media (max-width: 1279px) {
          font-size: 14px;
          line-height: 18px;
        }

        ::-webkit-input-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
        ::-moz-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
        :-ms-input-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
        :-moz-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
      }

      & textarea {
        border: 1px solid #0043ea;
        padding: 5px 10px;
        resize: none;

        @media (max-width: 1279px) {
          /* border: 0.05em solid #0043ea; */
          border-width: 0.05em;
          margin: 1px 0 0 0;
        }
      }
    }
  }

  & .thank-you-message {
    & p {
      margin: 0;
    }
  }
`;

const SubmitButtonContainer = styled.div`
  & .top-line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    margin: 30px 0 0 0;

    @media (max-width: 1279px) {
      justify-content: flex-end;
    }

    & p {
      margin: 0;
    }

    @media (max-width: 1279px) {
      margin: 20px 0 0 0;
    }

    & button {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.075em;

      padding: 0 10px 1px 10px;
      margin: 0 0 0 auto;
      height: 32px;

      border: 1px solid #0043ea;
      color: #0043ea;

      transition: 300ms background ease-in, 300ms color ease-in;

      @media (max-width: 1279px) {
        /* border: 0.09em solid #0043ea; */

        border-width: 0.07em;

        font-size: 10px;
        line-height: 13px;
        height: 20px;

        padding: 0 8px;
        margin: 0;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #ebe6fd;
          background-color: #0043ea;
        }
      }

      /* &:disabled,
      &[disabled] {
        &:hover,
        &:focus,
        &:focus-within {
          color: #000;
          background-color: transparent;

          -webkit-text-stroke: 0.1px #000;
        }
      } */
    }
  }
`;

export const ContactForm = () => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);

  const { register, handleSubmit, errors, reset } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeForm({
        "form-name": `contact-form`,
        ...data,
      }),
    })
      .then(() => {
        setIsFormVisible(false);
        setFormMessage("Thank you for getting in touch.");
        reset();
      })
      .catch(error => {
        console.log(error);
        setIsFormVisible(false);
        setFormMessage(
          "An error occurred while submitting your enquiry. Please email us directly."
        );
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const closeForm = setTimeout(() => {
        setIsFormVisible(false);
        const fadeInForm = setTimeout(() => {
          setIsFormVisible(true);
        }, 750);
        return () => clearTimeout(fadeInForm);
      }, 8000);
      return () => clearTimeout(closeForm);
    }
  }, [isFormVisible]);

  return (
    <ContactFormContainer>
      {isFormVisible && (
        <form
          name="contact-form"
          id="contact-form"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact-form" />

          <div className="grid grid-2">
            <div className="field">
              <input
                id="contact-form-fName"
                name="fName"
                type="text"
                autoComplete="given-name"
                aria-required="true"
                aria-label="First Name"
                placeholder={
                  errors.fName ? `This field is required` : `First Name`
                }
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <input
                id="contact-form-lName"
                name="lName"
                type="text"
                autoComplete="family-name"
                aria-required="true"
                aria-label="Last Name"
                placeholder={
                  errors.lName ? `This field is required` : `Last Name`
                }
                ref={register({
                  required: true,
                })}
              />
            </div>
          </div>

          <div className="field">
            <input
              id="contact-form-email"
              name="email"
              type="email"
              autoComplete="email"
              aria-required="true"
              aria-label="Email"
              placeholder={
                errors.email ? `Enter a valid email address` : `Email`
              }
              ref={register({
                required: true,
              })}
            />
          </div>

          <div className="field textarea">
            <label className="uppercase" htmlFor="message">
              Message
            </label>
            <textarea
              id="contact-form-message"
              name="message"
              type="message"
              aria-required="true"
              aria-label="Message"
              placeholder={errors.message ? `Please add a message` : ``}
              ref={register({
                required: true,
              })}
              rows="5"
            />
          </div>

          <SubmitButtonContainer>
            <div className="errors-container">
              {(errors.fName ||
                errors.lName ||
                errors.email ||
                errors.message) && (
                <p>Please complete the missing information</p>
              )}
            </div>

            <div className="top-line-container">
              <button type="submit" className="submit-button" tabIndex="0">
                Submit
              </button>
            </div>
          </SubmitButtonContainer>
        </form>
      )}

      {!isFormVisible && (
        <div className="thank-you-message" id="thank_you_message">
          <p>{formMessage}</p>
        </div>
      )}
    </ContactFormContainer>
  );
};
