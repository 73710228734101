import React, { useEffect, useState } from "react";

// Styles
import "../styles/plyr.css";

export const PlyrVideo = ({ video }) => {
  const [player, setPlayer] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const Plyr = require("plyr");

      setPlayer(
        new Plyr(`#video-${video.prismicId}`, {
          debug: false,
          autoplay: false,
          muted: false,
          loop: { active: false },
          invertTime: false,
          controls: ["play-large"],
          hideControls: true,
          youtube: {
            modestbranding: 1,
            playsinline: 1,
            autoplay: 0,
            showinfo: 0,
            controls: 0,
          },
          clickToPlay: true,
        })
      );
    }
  }, []);

  if (video.embed_url !== null) {
    return (
      <div
        className="plyr__video-embed"
        id={`video-${video.prismicId}`}
        dangerouslySetInnerHTML={{
          __html: video.html,
        }}
      />
    );
  } else {
    return null;
  }
};
