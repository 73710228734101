import React, { useContext, useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import styled from "styled-components";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";

// Icons
import { LinkArrow } from "../components/icons/link-arrow";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: calc(100svh - 78px);
  padding: 250px 30px 200px 30px;

  @media (max-width: 1279px) {
    padding: 125px 20px 145px 20px;
  }

  & section {
    margin: 0 auto 150px auto;

    & .section-title {
      margin: 0 0 50px 0;
    }

    &:last-of-type {
      margin: 0 auto;
    }
  }

  @media (max-width: 1279px) {
    & section {
      margin: 0 auto 60px auto;

      & .section-title {
        margin: 0 0 25px 0;
      }
    }
  }

  & .jobs-outer-container {
    margin: 0 auto;

    & .jobs-container {
      & .single-job-listing {
        position: relative;

        max-width: 1150px;
        margin: 0 auto;

        /* padding: 0 30px; */

        & h1 {
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }

        & .profile-container {
          position: relative;

          z-index: 10;
        }

        & .jobs-excerpt-text {
          margin: 0 0 20px 0;
        }

        & span {
          padding: 5px 0;
          display: block;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & .stem {
              width: 80px;

              @media (max-width: 1279px) {
                width: 40px;
              }
            }
          }
        }

        &.layout-1 {
          & .inner-single-job-listing-container {
            max-width: 680px;
            margin: 0 0 60px 115px;
          }
        }

        &.layout-2 {
          & .inner-single-job-listing-container {
            max-width: 560px;
            margin: 0 115px 60px 0;
          }
        }

        &.layout-3 {
          & .inner-single-job-listing-container {
            max-width: 680px;
            margin: 0 0 60px 0;
          }
        }

        &.layout-4 {
          & .inner-single-job-listing-container {
            max-width: 680px;
            margin: 0 115px 60px auto;
          }
        }

        & p {
          margin: 0;
        }
      }
    }

    & .full-team-member-information {
      cursor: pointer;

      transition: 350ms all linear;
      display: none;
      /* overflow: hidden; */
      /* max-height: 0; */

      &.open {
        display: block;
        /* max-height: 100vh; */
        background-color: #fdebe8;
        padding: 60px 30px;
        margin: 0 0 60px 0;
      }

      & .full-team-member-text {
        max-width: 680px;
        margin: 0 auto;

        padding: 0 0 100px 0;

        & p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
`;

const Jobs = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageType: `privacy-policy`,
    });
  }, []);

  useEffect(() => {
    if (data.prismicJobs === null) {
      navigate("/404/");
    }
  }, [data]);

  if (data.prismicJobs === null) return <></>;

  const items = data.prismicJobs.data.jobs.map((job, index) => {
    return (
      <li
        key={`single_job_listing_${index}`}
        className={`single-job-listing layout-${index + 1}`}
      >
        <div className="profile-container">
          <div className="inner-single-job-listing-container">
            <Link to={`${job.job.document.url}`}>
              <div className="jobs-excerpt-text">
                <div
                  className="job-title uppercase"
                  dangerouslySetInnerHTML={{
                    __html: job.job.document.data.title.html,
                  }}
                />

                <div
                  className="job-excerpt"
                  dangerouslySetInnerHTML={{
                    __html: job.job.document.data.excerpt.html,
                  }}
                />
              </div>

              <span>
                <LinkArrow fill={`#0043EA`} />
              </span>
            </Link>
          </div>
        </div>
      </li>
    );
  });

  return (
    <Page>
      <section className="jobs-outer-container">
        <div
          className="uppercase center-text section-title glossy-display"
          dangerouslySetInnerHTML={{
            __html: data.prismicJobs.data.title.html,
          }}
        />

        <div className="jobs-container">{items}</div>
      </section>
    </Page>
  );
};

export default withPrismicPreviewResolver(Jobs);

export const query = graphql`
  {
    prismicJobs {
      _previewable
      data {
        title {
          html
        }
        text {
          html
        }
        jobs {
          job {
            document {
              ... on PrismicJobListing {
                id
                url
                data {
                  title {
                    html
                  }
                  excerpt {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
