import React, { useContext, useCallback, useState } from "react";
import { Link } from "gatsby";
import debounce from "lodash.debounce";

// Context
import { StoreContext } from "../context/store-context";

// Components
import { NumericInput } from "./numeric-input";

// Utils
import { formatPrice } from "../utils/format-price";

export const LineItem = ({ item, allArtistNames }) => {
  const { removeLineItem, checkout, updateLineItem, loading } = useContext(
    StoreContext
  );

  const [quantity, setQuantity] = useState(item.quantity);

  const price = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount)
  );

  const subtotal = formatPrice(
    item.variant.priceV2.currencyCode,
    Number(item.variant.priceV2.amount) * quantity
  );

  const handleRemove = () => {
    removeLineItem(checkout.id, item.id);
  };

  const uli = debounce(
    value => updateLineItem(checkout.id, item.id, value),
    300
  );
  // eslint-disable-next-line
  const debouncedUli = useCallback(value => uli(value), []);

  const handleQuantityChange = value => {
    if (value !== "" && Number(value) < 1) {
      return;
    }
    setQuantity(value);
    if (Number(value) >= 1) {
      debouncedUli(value);
    }
  };

  function doIncrement() {
    handleQuantityChange(Number(quantity || 0) + 1);
  }

  function doDecrement() {
    handleQuantityChange(Number(quantity || 0) - 1);
  }

  return (
    <div className="row item">
      <div className="item-details">
        <h3 className="item-title">
          <Link to={`/shop/${item.variant.product.handle}/`}>{item.title}</Link>
        </h3>
        {/* <div className="option">
          <p>{item.variant.selectedOptions[0].name}</p>
          <p className="">{item.variant.selectedOptions[0].value}</p>
        </div> */}
      </div>

      <div className="item-quantity">
        <NumericInput
          disabled={loading}
          value={quantity}
          aria-label="Quantity"
          onIncrement={doIncrement}
          onDecrement={doDecrement}
          onChange={e => handleQuantityChange(e.currentTarget.value)}
        />
      </div>

      <div className="item-price">
        {subtotal}
        <button onClick={handleRemove} className="uppercase remove-button">
          ✕
        </button>
      </div>
    </div>
  );
};
