import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useInView } from "react-intersection-observer";

export const HomepageImage = ({ content, isMobile }) => {
  const { ref, inView, entry } = useInView({
    rootMargin: "-35% 0px",
    threshold: 0,
  });

  return (
    <div
      className={`single-text-row text-with-image position-${content.primary.position.toLowerCase()} mobile-view-${
        inView && isMobile ? `visible` : `hidden`
      }`}
      ref={ref}
    >
      <div className="text-hover-container">
        <img src={content.primary.text_svg.url} className="svg-text" />
      </div>

      <GatsbyImage
        image={content.primary.image.gatsbyImageData}
        alt={
          content.primary.image.alt !== null ? content.primary.image.alt : ""
        }
      />
    </div>
  );
};
