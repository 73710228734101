import React from "react";
import styled from "styled-components";

// Icons
import { Logo } from "../icons/logo";

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px 30px;

  & ol {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & li {
      margin: 0 40px 0 0;

      & a {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.075em;

        &.current,
        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 4px;
        }
      }

      @media (max-width: 1439px) {
        margin: 0 30px 0 0;

        & a {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
`;

export const DesktopMenu = ({ links }) => (
  <HeaderContainer>
    <ol>{links}</ol>

    <Logo />
  </HeaderContainer>
);
