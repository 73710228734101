import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import { useSessionStorage } from "react-use";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";

// Get Urls correct
import { Link } from "gatsby";
import { PrismicProvider } from "@prismicio/react";
import { linkResolver } from "../link-resolver/linkResolver";

// Components
import { Header } from "./header";
import { Footer } from "./footer";
import { Splash } from "./splash";
import { Cookies } from "./cookies";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Context
import { PageSetup } from "../context/page-setup";

// Components
import { DefaultSEO } from "./default-seo";

// Transiitons
import { Transition } from "../transition/transition";

const Container = styled.div`
  position: relative;
  padding: 0;

  transition: 300ms background-color ease, 300ms color ease;
  transition-delay: 100ms;

  & main {
    opacity: ${props => props.opacity};
    transition: 350ms opacity ease;
    transition-delay: 500ms;
  }

  & header {
    transition: 300ms color ease;
    transition-delay: 100ms;

    & svg {
      & path {
        transition: 300ms fill ease;
      }
    }
  }

  & footer {
    transition: 300ms background-color ease, 300ms color ease;
    transition-delay: 100ms;

    & .cart-link {
      display: none;
    }
  }

  &.page-type-home {
    background-color: #d6cbfb;
    color: #0043ea;
    padding: 0;

    & header {
      // background-color: #d0c5fa;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #dcede9;
        }
        /* } */
      }
    }

    & footer {
      background-color: #b6d8d0;

      & a,
      & button {
        color: #0043ea;
      }

      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }
  }

  &.page-type-about {
    background-color: #dcede9;
    color: #0043ea;

    padding: 0;

    & header {
      // background-color: #dcede9;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #fdebe8;
        }
        /* } */
      }
    }

    & footer {
      background-color: #dcede9;

      & a,
      & button {
        color: #0043ea;
      }
      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }
  }

  &.page-type-work {
    // background-color: #d0c5fa;
    color: #0043ea;

    & header {
      // background-color: #d0c5fa;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #dcede9;
        }
        /* } */
      }
    }

    & footer {
      // background-color: #d0c5fa;

      & a,
      & button {
        color: #0043ea;
      }
      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }

    &.purple-background {
      background-color: #d6cbfb;
    }

    &.blue-background {
      background-color: #c2d5e9;
    }

    &.orange-background {
      background-color: #f2c8ba;
    }

    &.yellow-background {
      background-color: #e4d99b;
    }

    &.green-background {
      background-color: #b6d8d0;
    }

    &.red-text {
      color: #0043ea;

      & header {
        color: #0043ea;

        & svg {
          & path {
            fill: #0043ea;
          }
        }
      }

      & footer {
        & a,
        & button {
          color: #0043ea;
        }
        & svg {
          & path {
            fill: #0043ea;
          }
        }
      }
    }
  }

  &.page-type-fund {
    background-color: #faf7eb;
    color: #0043ea;

    & header {
      // background-color: #faf7eb;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #e4edf5;
        }
        /* } */
      }
    }

    & footer {
      background-color: #faf7eb;

      & a,
      & button {
        color: #0043ea;
      }

      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }
  }

  &.page-type-news {
    background-color: #fdebe8;
    color: #0043ea;

    & header {
      // background-color: #fdebe8;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #ebe6fd;
        }
        /* } */
      }
    }

    & footer {
      background-color: #fdebe8;

      & a,
      & button {
        color: #0043ea;
      }

      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }
  }

  &.page-type-shop,
  &.page-type-product {
    background-color: #e4edf5;
    color: #0043ea;
    padding: 0;

    & header {
      // background-color: #e4edf5;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #faf7eb;
        }
        /* } */
      }
    }

    & footer {
      background-color: #e4edf5;

      & a,
      & button {
        color: #0043ea;
      }

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      & .cart-link {
        background-color: #e4edf5;
      }
    }
  }

  &.page-type-contact {
    background-color: #ebe6fd;
    color: #0043ea;

    & header {
      // background-color: #ebe6fd;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        & nav {
          background-color: #dcede9;
        }
      }
    }

    & footer {
      background-color: #ebe6fd;

      & a,
      & button {
        color: #0043ea;
      }
      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }
  }

  &.page-type-cart {
    background-color: #faf7eb;
    color: #0043ea;

    & header {
      // background-color: #faf7eb;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        /* &.open { */
        & nav {
          background-color: #e4edf5;
        }
        /* } */
      }
    }

    & footer {
      background-color: #faf7eb;

      & a,
      & button {
        color: #0043ea;
      }
      & svg {
        & path {
          fill: #0043ea;
        }
      }

      & .cart-link {
        background-color: #faf7eb;
      }
    }
  }

  &.page-type-privacy-policy {
    background-color: #dcede9;
    color: #0043ea;

    & header {
      // background-color: #DCEDE9;
      color: #0043ea;

      & svg {
        & path {
          fill: #0043ea;
        }
      }

      @media (max-width: 1279px) {
        & nav {
          background-color: #fdebe8;
        }
      }
    }

    & footer {
      background-color: #dcede9;

      & a,
      & button {
        color: #0043ea;
      }
      & svg {
        & path {
          fill: #0043ea;
        }
      }
    }
  }

  &.page-type-project {
    &.purple-background {
      background-color: #d6cbfb;

      color: #0043ea;

      & nav {
        & button {
          color: #0043ea;
        }

        & svg {
          & path {
            fill: #0043ea;
          }
        }
      }
    }

    &.blue-background {
      background-color: #c2d5e9;

      &.active-section-hook,
      &.active-section-information,
      &.active-section-press,
      &.active-section-images,
      &.active-section-trailer {
        background-color: #e4edf5;
      }
    }

    &.orange-background {
      background-color: #f2c8ba;
      color: #0043ea;

      &.active-section-hook,
      &.active-section-information,
      &.active-section-press,
      &.active-section-images,
      &.active-section-trailer {
        background-color: #fdebe8;
      }

      & nav {
        & button {
          color: #0043ea;
        }

        & svg {
          & path {
            fill: #0043ea;
          }
        }
      }
    }

    &.yellow-background {
      background-color: #e4d99b;

      &.active-section-hook,
      &.active-section-information,
      &.active-section-press,
      &.active-section-images,
      &.active-section-trailer {
        background-color: #faf7eb;
      }
    }

    &.green-background {
      background-color: #b6d8d0;

      &.active-section-hook,
      &.active-section-information,
      &.active-section-press,
      &.active-section-images,
      &.active-section-trailer {
        background-color: #dcede9;
      }
    }

    &.red-text {
      color: #0043ea;

      & nav {
        & button {
          color: #0043ea;
        }

        & svg {
          & path {
            fill: #0043ea;
          }
        }
      }
    }

    &.blue-text {
      color: #0043ea;

      & nav {
        & button {
          color: #0043ea;
        }

        & svg {
          & path {
            fill: #0043ea;
          }
        }
      }
    }
  }

  & .animation-exiting,
  & .animation-entering {
    & main {
      user-select: none;
      pointer-events: none;

      & .single-project {
        user-select: none;
        pointer-events: none;
      }
    }
  }
`;

const Layout = ({ children, location }) => {
  const [pageSetup, setPageSetup] = useState({
    pageColor: `#fff`,
    pageType: ``,
  });

  const [mainOpacity, setMainOpacity] = useState(0);

  const [isSplashOpen, setIsSplashOpen] = useSessionStorage(
    "isSplashOpen",
    true
  );
  const [hideSplash, setHideSplash] = useSessionStorage("hideSplash", false);

  let isDesktop = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    if (hideSplash) {
      setMainOpacity(1);
    }
  }, [hideSplash]);

  const closeSplash = () => {
    setMainOpacity(1);
    setIsSplashOpen(false);
    setHideSplash(true);
  };

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <PrismicProvider
      linkResolver={linkResolver}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      <PageSetup.Provider value={[pageSetup, setPageSetup]}>
        <Normalize />
        <GlobalStyles />
        <DefaultSEO />

        <Container
          className={`page-type-${pageSetup.pageType}`}
          opacity={mainOpacity}
        >
          {!pageSetup.pageType.includes(`project`) && (
            <Header
              isDesktop={isDesktop}
              location={location}
              pageType={pageSetup.pageType}
            />
          )}

          <Transition
            children={children}
            location={location}
            pageType={pageSetup.pageType}
          >
            <>
              <main>{children}</main>

              {!pageSetup.pageType.includes(`project`) && (
                <Footer
                  isDesktop={isDesktop}
                  location={location}
                  pageSetup={pageSetup}
                />
              )}
            </>
          </Transition>

          <Cookies location={location} />
        </Container>

        {isSplashOpen && (
          <Splash
            setIsSplashOpen={setIsSplashOpen}
            setHideSplash={setHideSplash}
            hideSplash={hideSplash}
            closeSplash={closeSplash}
          />
        )}
      </PageSetup.Provider>
    </PrismicProvider>
  );
};

export default Layout;
