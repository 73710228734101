import React, { useState } from "react";
import { PrismicRichText } from "@prismicio/react";

// Components
import { PlyrVideo } from "./plyr-video";

// Icons
import { LinkArrow } from "../icons/link-arrow";

export const DropdownVideo = ({ content }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="inner-video-container">
      <PrismicRichText field={content.primary.video_title.richText} />
      <PrismicRichText field={content.primary.short_video_text.richText} />

      <button
        type="button"
        className="hover-arrow"
        onClick={() => setIsOpen(!isOpen)}
      >
        <LinkArrow fill={`#0043ea`} />
      </button>

      {isOpen && (
        <div className={"dropdown"}>
          <PlyrVideo video={content.primary.video} />

          <PrismicRichText field={content.primary.video_text.richText} />
        </div>
      )}
    </div>
  );
};
