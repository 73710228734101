import React, { useContext } from "react";
import { useStaticQuery, graphql } from "gatsby";

// Context
import { StoreContext } from "../context/store-context";
import { DesktopFooter } from "../footer/desktop-footer";
import { MobileFooter } from "../footer/mobile-footer";

export const Footer = ({ isDesktop, location, pageSetup }) => {
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  const data = useStaticQuery(graphql`
    query {
      prismicMenus {
        data {
          footer_social_links {
            website
            link {
              url
            }
            svg_logo {
              url
            }
            visible_on_website
          }
        }
      }
    }
  `);

  if (isDesktop) {
    return (
      <DesktopFooter
        location={location}
        pageSetup={pageSetup}
        quantity={quantity}
        data={data}
      />
    );
  } else {
    return (
      <MobileFooter
        location={location}
        pageSetup={pageSetup}
        quantity={quantity}
        data={data}
      />
    );
  }
};
