import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html,
html.wf-loading {
  body {
    opacity: 0;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}

html,
body {
	overscroll-behavior: none;
}



p:empty {
  display: block;
  height: 1em;
  margin: 0;
}

body {
  font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.025em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  @media (max-width: 1439px) {
    font-size: 19px;
    line-height: 24px;
  }

  @media (max-width: 1279px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.glossy-display,
.glossy-display > h1,
.glossy-display > a > h1,
.glossy-display > h2,
.glossy-display > h3,
.glossy-display > p {
  font-family: "GlossyDisplay", "Helvetica Neue", "Lucida Grande", sans-serif;
}

*:focus{
  outline: 0;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 35px;
  line-height: 45px;
  letter-spacing: 0.075em;

  @media (max-width: 1279px) {
    font-size: 18px;
    line-height: 23px;
  }
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.075em;
}

/* Heading 1 */
h3 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.075em;

  @media (max-width: 1279px) {
    font-size: 11.5px;
    line-height: 15px;
  }
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.075em;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.075em;
}

.uppercase,
.uppercase > p {
  text-transform:uppercase;
}

.center-text {
  text-align:center;
}

strong {
  font-weight: 400;
  font-style: normal;
}

/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border:0;
    appearance: none;
    cursor: pointer;
    font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande", sans-serif;
    font-weight: 400;

    padding:0;
    color: inherit;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}



/*--------------------------------------------------------------
  ## Inputs
  --------------------------------------------------------------*/

  input {
    background-color: transparent;
    border-radius: 0;
  }
`;

export default GlobalStyle;
