import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { useWindowScroll } from "react-use";

// Components
import { FooterNewsletter } from "../forms/footer-newsletter";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 30px;

  line-height: 75px;

  &.page-type-shop {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    & .cart-link {
      display: block;
    }

    & .main-footer-menu {
      transition: 250ms opacity ease;
    }

    &.menu-hidden {
      & .main-footer-menu {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.menu-visible {
      & .main-footer-menu {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &.page-type-product {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    & .cart-link {
      display: block;
    }
  }

  & ol {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      & a,
      & button {
        font-size: 16px;
        line-height: 75px;
        letter-spacing: 0.075em;
        text-transform: uppercase;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
          }
        }

        @media (max-width: 1439px) {
          font-size: 14px;
        }
      }

      margin: 0 40px 0 0;

      &:last-of-type {
        margin: 0;
      }

      @media (max-width: 1439px) {
        margin: 0 30px 0 0;
      }
    }
  }

  & .cart-link {
    & a {
      display: block;
      width: fit-content;
      height: 31px;

      padding: 0 10px;
      border: 1px solid #0043ea;

      font-size: 16px;
      line-height: 30px;

      letter-spacing: 0.075em;
      color: #0043ea;

      transition: 300ms background ease-in, 300ms color ease-in;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: rgba(245, 59, 7, 0.8);
          color: #e4edf5;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 20px;
    line-height: 25px;

    & .main-footer-menu {
      display: block;

      & li {
        & a,
        & button {
          line-height: 25px;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & ol {
      & li {
        & a,
        & button {
          font-size: 11.5px;
          line-height: 15px;
          letter-spacing: 0.075em;
        }
      }
    }

    & .cart-link {
      & li {
        & a {
          font-size: 10px;
          line-height: 20px;

          height: 20px;
        }
      }
    }
  }
`;

export const DesktopFooter = ({ quantity, location, pageSetup, data }) => {
  const { x, y } = useWindowScroll();
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isNewsletterVisible, setIsNewsletterVisible] = useState(false);

  useEffect(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 10
    ) {
      setIsMenuVisible(true);
    } else {
      setIsMenuVisible(false);
    }
  }, [y]);

  const links = data.prismicMenus.data.footer_social_links
    .filter(link => link.visible_on_website)
    .map((link, index) => (
      <li key={`single_desktop_external_link_${index}`}>
        <a href={link.link.url} target="_blank" rel="noopener noreferrer">
          {link.website}
        </a>
      </li>
    ));

  return (
    <>
      <FooterWrapper
        className={`page-type-${pageSetup.pageType} menu-${
          isMenuVisible ? `visible` : `hidden`
        }`}
      >
        <ol className="main-footer-menu">
          <li>
            <Link to={`/privacy-policy/`}>Terms & Legal</Link>
          </li>
          <li>
            <button onClick={() => setIsNewsletterVisible(true)}>
              THE NEWLETTER
            </button>
          </li>
          <li>
            <Link to={`/contact/`}>Contact</Link>
          </li>

          {links}
        </ol>

        <ol className="cart-link">
          <li>
            <Link to={`/cart/`} className="uppercase">
              Cart {quantity >= 1 ? `(${quantity})` : ``}
            </Link>
          </li>
        </ol>

        <FooterNewsletter
          isOpen={isNewsletterVisible}
          setIsNewsletterVisible={setIsNewsletterVisible}
          pageType={pageSetup.pageType}
        />
      </FooterWrapper>
    </>
  );
};
