import React, { useContext, useState, useEffect } from "react";
import { StoreContext } from "../context/store-context";

// Icons
import { LinkArrow } from "../icons/link-arrow";

export function AddToCart({ variantId, quantity, available, price, ...props }) {
  const { addVariantToCart, loading } = useContext(StoreContext);
  const [addedToCart, setAddedToCart] = useState(false);

  function addToCart(e) {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
    setAddedToCart(true);
  }

  useEffect(() => {
    if (addedToCart) {
      const resetAddedToCart = setTimeout(() => {
        setAddedToCart(false);
      }, 4000);
      return () => clearTimeout(resetAddedToCart);
    }
  }, [addedToCart]);

  return (
    <div className="product-buttons">
      <button
        type="submit"
        className="uppercase"
        onClick={addToCart}
        disabled={!available || loading}
        {...props}
      >
        {available ? (
          <>
            {addedToCart ? (
              <>Added to Cart</>
            ) : (
              <>
                Add to Cart <LinkArrow fill={`#0043ea`} />
              </>
            )}
          </>
        ) : (
          <>Out of Stock</>
        )}
      </button>
    </div>
  );
}
