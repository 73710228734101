import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";
import { StoreProvider } from "./src/components/context/store-context";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import About from "./src/pages/about";
import Cart from "./src/pages/cart";
import Contact from "./src/pages/contact";
import Index from "./src/pages/index";
import News from "./src/pages/news";
import PrivacyPolicy from "./src/pages/privacy-policy";
import Shop from "./src/pages/shop";
import TheFund from "./src/pages/the-fund";
import Work from "./src/pages/work";
import Jobs from "./src/pages/jobs";

// Templates
import Product from "./src/templates/product";
import Project from "./src/templates/project";
import JobListing from "./src/templates/job-listing";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          about: About,
          cart: Cart,
          contact: Contact,
          homepage: Index,
          news: News,
          privacy_policy: PrivacyPolicy,
          shop: Shop,
          the_fund: TheFund,
          work: Work,
          product: Product,
          project: Project,
          jobs: Jobs,
          job_listing: JobListing,
        }),
      },
    ]}
  >
    <StoreProvider>{element}</StoreProvider>
  </PrismicPreviewProvider>
);
