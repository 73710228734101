import React, { useState, useRef } from "react";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import { useMouse } from "react-use";
import { isMobile } from "react-device-detect";

// Icons
import { LinkArrow } from "../icons/link-arrow";

const ProfileImage = styled.div`
  z-index: 100;

  cursor: none;
  pointer-events: none;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: none;

  & .image-container {
    position: absolute;
    margin: 0;

    cursor: none;

    transition: 50ms top ease, 50ms left ease;

    width: 300px;
    height: 300px;

    & img {
      cursor: none;
    }
  }
`;

export const Profile = ({ person, setOpenPersonId, openPersonId }) => {
  const activeProfile = useRef(null);
  const activeProfileMouseData = useMouse(activeProfile);
  const [isPortraitVisible, setIsPortraitVisible] = useState(false);

  return (
    <>
      <div
        className="profile-container"
        ref={activeProfile}
        id={`id-${person.id}`}
      >
        <div className="inner-single-team-member-container">
          <div className="team-member-text">
            <div
              onMouseOver={() => {
                if (!isMobile) {
                  setIsPortraitVisible(true);
                }
              }}
              onMouseLeave={() => {
                if (!isMobile) {
                  setIsPortraitVisible(false);
                }
              }}
            >
              <PrismicRichText field={person.primary.name.richText} />
              <PrismicRichText field={person.primary.excerpt_text.richText} />
            </div>
          </div>

          <button
            aria-label={`View ${person.primary.name.text} biography`}
            onClick={() => {
              if (openPersonId === null) {
                setOpenPersonId(person.id);
              } else {
                if (openPersonId === person.id) {
                  setOpenPersonId(null);
                } else {
                  setOpenPersonId(person.id);
                }
              }
            }}
          >
            <LinkArrow fill={`#0043EA`} />
          </button>
        </div>
      </div>

      {isPortraitVisible && !isMobile && (
        <ProfileImage>
          <div
            className="image-container"
            style={{
              top: `${activeProfileMouseData.elY - 150}px`,
              left: `${activeProfileMouseData.elX - 150}px`,
            }}
          >
            <GatsbyImage
              image={person.primary.image.gatsbyImageData}
              alt={
                person.primary.name.text !== null
                  ? person.primary.name.text
                  : ``
              }
            />
          </div>
        </ProfileImage>
      )}
    </>
  );
};
