import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";

// Icons
import { LinkArrow } from "../components/icons/link-arrow";

// Utils
import { DangerouslySetHtmlContent } from "../components/utils/dangerously-set-html-content";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: calc(100svh - 78px);

  padding: 250px 30px 200px 30px;

  @media (max-width: 1279px) {
    padding: 125px 20px 145px 20px;
  }
`;

const TextContainer = styled.div`
  width: 100%;

  & .title-container {
    margin: 0 0 50px 0;
  }

  & .grid-container {
    /* display: grid;
    grid-template-columns: 2fr 1.25fr;
    grid-column-gap: 60px; */
  }

  & .text {
    max-width: 915px;
    margin: 0 auto;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & .about-text-container {
    /* margin: 0 0 0 0; */
    max-width: 915px;
    margin: 0 0 0 auto;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & strong {
    font-size: 15px;
    line-height: 27px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.075em;
    text-transform: uppercase;

    @media (max-width: 1279px) {
      font-size: 11.5px;
      line-height: 15px;
    }
  }

  & ul {
    list-style: disc;
    padding-inline-start: 40px;

    & li {
      list-style: disc;
    }
  }

  //
  & .typeform-container {
    margin: 2em 0;
    cursor: pointer;

    & a {
      font-size: 22px !important;
      line-height: 28px !important;

      text-transform: uppercase;
      text-decoration: none !important;
      letter-spacing: 0.075em;

      /* @media (max-width: 1279px) {
        font-size: 11.5px !important;
        line-height: 15px !important;
      } */

      @media (max-width: 1439px) {
        font-size: 19px !important;
        line-height: 24px !important;
      }

      @media (max-width: 1279px) {
        font-size: 14px !important;
        line-height: 18px !important;
      }
    }

    & .arrow-container {
      margin: 20px 0 0 0;
      padding: 5px 0;
      width: fit-content;
      pointer-events: none;
      cursor: none;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        & .stem {
          width: 80px;

          @media (max-width: 1279px) {
            width: 40px;
          }
        }
      }
    }
  }
`;

const JobListing = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageType: `privacy-policy`,
    });
  }, []);

  return (
    <Page>
      <TextContainer>
        <div
          className="title-container uppercase center-text section-title glossy-display"
          dangerouslySetInnerHTML={{
            __html: data.prismicJobListing.data.title.html,
          }}
        />

        <div className="grid-container">
          <div className="text">
            <div
              className="text-container"
              dangerouslySetInnerHTML={{
                __html: data.prismicJobListing.data.text.html,
              }}
            />
            <div className="typeform-container">
              <DangerouslySetHtmlContent
                html={data.prismicJobListing.data.typeform_embed.text}
                allowRerender={true}
              />
              <div className="arrow-container">
                <LinkArrow fill={`#0043EA`} />
              </div>
            </div>
          </div>

          <div
            className="about-text-container"
            dangerouslySetInnerHTML={{
              __html: data.prismicJobListing.data.right_column_text.html,
            }}
          />
        </div>
      </TextContainer>
    </Page>
  );
};

export default withPrismicPreviewResolver(JobListing);

export const query = graphql`
  query JobListing($uid: String) {
    prismicJobListing(uid: { eq: $uid }) {
      _previewable
      data {
        title {
          html
        }
        text {
          html
        }
        right_column_text {
          html
        }
        typeform_embed {
          html
          text
        }
      }
    }
  }
`;
