import React, { useContext, useEffect, useState, useCallback } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { use100vh } from "react-div-100vh";

// Context
import { StoreContext } from "../components/context/store-context";
import { PageSetup } from "../components/context/page-setup";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Components
import { VariantSelectors } from "../components/product/variant-selectors";
import { AddToCart } from "../components/product/buttons";
import { Gallery } from "../components/images/gallery";

// Utils
import { formatPrice } from "../components/utils/format-price";
import isEqual from "lodash.isequal";
import { AspectRatioImageContainer } from "../components/containers/aspect-ratio-image-container";

const Page = styled.div`
  padding: 150px 0;
  min-height: ${props => props.height}px;

  @media (max-width: 768px) {
    padding: 125px 0;
  }

  & .product-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    min-height: calc(${props => props.height}px - 150px - 150px);

    margin: 0 30px;

    & .inner-content-container {
      grid-column: 2 / 12;
    }

    @media (max-width: 1279px) {
      margin: 0 20px;
    }

    @media (max-width: 768px) {
      margin: 0;
      min-height: unset;
    }
  }

  & .grid-12 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    padding: 0 150px;

    & .left-column {
      grid-column: 1 / 9;

      @media (max-width: 768px) {
        grid-column: unset;
      }
    }

    & .right-column {
      grid-column: 9 / 13;

      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 768px) {
        grid-column: unset;
      }

      & .text-container {
        width: 100%;
        max-width: 330px;
        margin: 0 30px 0 auto;

        /* border: 1px dashed red; */

        @media (max-width: 768px) {
          position: relative;
          top: unset;
          max-width: unset;

          padding: 0 20px;
        }

        & .product-title {
          & h1 {
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.075em;

            @media (max-width: 1279px) {
              font-size: 16px;
              line-height: 20px;
            }
          }

          /* @media (max-width: 768px) {
            margin: 0;

            & h1 {
              font-size: 18px;
              line-height: 21px;
            }
          } */
        }

        & .product-description {
          & p {
            &:first-of-type {
              margin-top: 0;
            }

            &:last-of-type {
              font-size: 15px;
              line-height: 19px;
            }

            & br,
            & strong {
              font-size: 15px;
              line-height: 19px;
              letter-spacing: 0.025em;
            }
          }
        }

        & .product-options-container {
          & .variant-options-container {
            display: grid;
            grid-template-columns: 60px 120px;
            align-items: flex-end;

            & label {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.02em;
            }

            & select {
              border: 0;
              font-size: 16px;

              background-color: transparent;
              color: #0043ea;

              &:focus {
                outline: 0;
              }
            }
          }
        }

        & .product-buttons {
          width: 100%;
          margin: 80px 0 0 0;

          & button {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.075em;

            color: #0043ea;

            width: 100%;

            text-align: left;
            padding: 0;

            & .link-arrow-container {
              margin: 2px 0 0 15px;
            }

            @media (max-width: 1279px) {
              font-size: 16px;
              line-height: 20px;
            }

            @media (hover: hover) and (pointer: fine) {
              &:hover {
                & .stem {
                  width: 80px;

                  @media (max-width: 768px) {
                    width: 40px;
                    border-width: 0.035em;
                  }
                }
              }
            }
          }
        }

        & .product-links {
          & button {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.02em;

            &:first-of-type {
              margin: 0 30px 0 0;
            }
          }
        }
      }
    }

    @media (max-width: 1600px) {
      padding: 0 100px;
    }

    @media (max-width: 1500px) {
      padding: 0 80px;
    }

    @media (max-width: 1400px) {
      padding: 0;

      & .left-column {
        grid-column: 1 / 8;
      }
    }

    @media (max-width: 768px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      width: 100%;

      & .left-column,
      & .right-column {
        grid-column: unset;
      }
    }
  }

  @media (max-width: 768px) {
    & .product-container {
      & .left-column {
      }

      & .right-column {
        & .text-container {
          margin: 0 auto;

          & .product-title {
            margin: 30px 0 0 0;

            & h1 {
              font-size: 11.5px;
              line-height: 15px;
            }
          }

          & .product-description {
            & p {
              &:last-of-type {
                font-size: 14px;
                line-height: 18px;

                & strong {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }
          }

          & .product-buttons {
            margin: 40px 0 0 0;

            & button {
              font-size: 11.5px;
              line-height: 15px;
            }
          }
        }
      }
    }
  }
`;

const Product = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const height = use100vh();

  useEffect(() => {
    setPageSetup({
      pageType: `product`,
    });
  }, []);

  const product = data.shopifyProduct;

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
  } = product;

  const { client } = useContext(StoreContext);

  const [variant, setVariant] = useState({
    ...initialVariant,
  });
  const [quantity, setQuantity] = useState(1);

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    productId => {
      client.product.fetch(productId).then(fetchedProduct => {
        const result =
          fetchedProduct?.variants.filter(
            variant => variant.id === productVariant.storefrontId
          ) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  const handleOptionChange = (index, value) => {
    if (value === "") {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find(variant => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  );

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 768px)");

  const media = data.shopifyProduct.media.map((media, index) => {
    if (media.mediaContentType === "IMAGE") {
      return (
        <div
          key={`single_product_image_container_${index}`}
          className={`single-image-container single-media-container ${
            media.image.width > media.image.height ? `landscape` : `portrait`
          }`}
        >
          {isDesktop ? (
            <GatsbyImage
              image={media.image.gatsbyImageData}
              alt={media.alt !== null ? media.alt : ""}
              draggable="false"
            />
          ) : (
            <AspectRatioImageContainer image={null} padding={75}>
              <GatsbyImage
                image={media.image.gatsbyImageData}
                alt={media.alt !== null ? media.alt : ""}
                draggable="false"
              />
            </AspectRatioImageContainer>
          )}
        </div>
      );
    }

    if (media.mediaContentType === "VIDEO") {
      const sources = media.sources.map((source, index) => (
        <source
          src={source.url}
          type={`video/${source.format}`}
          key={`single_video_${index}`}
        />
      ));
      return (
        <div
          key={`single_product_video_container_${index}`}
          className={`single-video-container single-media-container`}
        >
          <video autoPlay muted loop playsInline>
            {sources}
          </video>
        </div>
      );
    }
  });

  const hasVariants = variants.length > 1;

  return (
    <>
      <Page height={height}>
        <section className="product-container">
          <div className="grid-12">
            <div className="left-column">
              <Gallery media={media} />
            </div>

            <div className="right-column">
              <div className="text-container">
                <div className="inner-text-container">
                  <div className="product-title">
                    <h1 className="uppercase">
                      {data.shopifyProduct.title} — {price}
                    </h1>
                  </div>

                  <div
                    className="product-description"
                    dangerouslySetInnerHTML={{
                      __html: data.shopifyProduct.descriptionHtml,
                    }}
                  />

                  <div className="product-options-container">
                    {hasVariants &&
                      options.map(({ id, name, values }, index) => (
                        <div key={`single_variant_${id}_${index}`}>
                          <VariantSelectors
                            handleOptionChange={handleOptionChange}
                            items={values}
                            name={name}
                            index={index}
                          />
                        </div>
                      ))}
                  </div>

                  <AddToCart
                    variantId={productVariant.storefrontId}
                    quantity={quantity}
                    available={available}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </>
  );
};

export default Product;

export const query = graphql`
  query ProductQuery($id: String, $collection: String) {
    shopifyProduct(id: { eq: $id }) {
      title
      descriptionHtml
      description
      productType
      tags
      handle
      shopifyId
      featuredImage {
        originalSrc
      }
      collections {
        title
        handle
      }
      seo {
        description
        title
      }
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      variants {
        id
        title
        price
        availableForSale
        shopifyId
        storefrontId
        selectedOptions {
          name
          value
        }
      }
      metafields {
        value
        key
      }
      options {
        name
        values
        shopifyId
      }
      media {
        alt
        ... on ShopifyVideo {
          id
          sources {
            url
            width
            height
            format
          }
          mediaContentType
        }
        ... on ShopifyMediaImage {
          id
          image {
            width
            height
            originalSrc
            gatsbyImageData(layout: FULL_WIDTH)
          }

          mediaContentType
        }
        ... on ShopifyExternalVideo {
          id
          embeddedUrl
          mediaContentType
        }
      }
    }
    allShopifyProduct(
      filter: {
        status: { eq: ACTIVE }
        id: { ne: $id }
        collections: { elemMatch: { id: { eq: $collection } } }
      }
    ) {
      nodes {
        title
        descriptionHtml
        description
        productType
        tags
        handle
        shopifyId
        featuredImage {
          gatsbyImageData
        }
        collections {
          title
          handle
        }
        seo {
          description
          title
        }
        priceRangeV2 {
          maxVariantPrice {
            amount
            currencyCode
          }
          minVariantPrice {
            amount
            currencyCode
          }
        }
        storefrontId
        variants {
          id
          title
          price
          availableForSale
          shopifyId
          storefrontId
          inventoryQuantity
          selectedOptions {
            name
            value
          }
        }
        options {
          name
          values
          shopifyId
        }
      }
    }
  }
`;
