import React from "react";

export const CloseProject = () => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M39 0.617667L38.3909 0L19.5 18.8867L0.617805 0L0 0.617667L18.8909 19.4956L0 38.3823L0.617805 39L19.5 20.1133L38.3909 39L39 38.3823L20.1178 19.4956L39 0.617667Z"
      fill="#0043ea"
    />
  </svg>
);
