import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

export const Header = ({ isDesktop, location }) => {
  const data = useStaticQuery(graphql`
    query {
      prismicMenus {
        data {
          header_menu {
            link {
              document {
                ... on PrismicAbout {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicContact {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicShop {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicTheFund {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicNews {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicWork {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicJobs {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const content = data.prismicMenus.data.header_menu
    .filter(link => link.link.document !== null)
    .map((link, index) => (
      <li key={`single_header_menu_link_${index}`} className="uppercase">
        <Link
          to={link.link.document.url}
          activeClassName="current"
          partiallyActive
        >
          {link.link.document.data.title.text}
        </Link>
      </li>
    ));

  if (isDesktop) {
    return <DesktopMenu links={content} location={location} />;
  } else {
    return <MobileMenu links={content} location={location} />;
  }
};
