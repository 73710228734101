import React, { useContext, useEffect } from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// SEO
// import { PageSeo } from "../components/seo/seo";

// Context
// import { PageType } from "../components/context/page-type";
import { StoreContext } from "../components/context/store-context";
import { PageSetup } from "../components/context/page-setup";

// Components
import { LineItem } from "../components/product/line-item";

// Icons
// import { MailchimpArrow, MobileArrow } from "../components/icons/arrows";

// Utils
import { formatPrice } from "../components/utils/format-price";

const Page = styled.div`
  padding: 170px 0 0 0;

  min-height: calc(${props => props.height}px - 78px);

  @media (max-width: 1279px) {
    padding: 125px 20px 0 20px;
    min-height: ${props => props.height}px;
  }

  & .page-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;

    & .empty-cart,
    & .table-container {
      grid-column: 2 / 12;
    }

    &.cart-is-empty {
      height: 100%;
      min-height: calc(${props => props.height}px - 170px - 170px);
      align-items: center;
    }

    @media (max-width: 1279px) {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;

      & .empty-cart,
      & .table-container {
        grid-column: unset;
      }

      &.cart-is-empty {
        display: flex;
        height: 100%;
        min-height: calc(${props => props.height}px - 125px - 125px);
        align-items: center;

        & p {
          max-width: 140px;
          margin: 0 auto;
          text-align: center;
        }
      }
    }
  }

  & .empty-cart {
    flex: 100%;
    width: 100%;

    & p {
      margin: 0;
    }
  }

  & .buttons-container {
    margin: 50px 0 0 0;

    width: 100%;

    & a {
      display: block;

      height: 30px;
      width: fit-content;

      margin: 0 0 15px 0;
      padding: 0 10px;

      font-size: 16px;
      line-height: 29px;
      letter-spacing: 0.075em;

      border: 1px solid #0043ea;
      /* border: 1px solid #f53b07; */
      color: #0043ea;

      transition: 300ms background ease-in, 300ms color ease-in;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: rgba(245, 59, 7, 0.8);
          color: #e4edf5;
        }
      }
    }

    @media (max-width: 1279px) {
      margin: 35px 0 0 0;

      & .column {
        &.left {
          display: none;
          grid-column: unset !important;
        }

        &.right {
          grid-column: 1 / 7 !important;
        }
      }

      & a {
        font-size: 10px;
        line-height: 19px;
        height: 20px;

        padding: 0 8px;

        @media (max-width: 1279px) {
          border-width: 0.07em;
        }
      }
    }
  }
`;

const Table = styled.div`
  width: 100%;

  & .header-row {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 20px;

    /* border-bottom: 1px solid #f53b07; */
    border-bottom: 1px solid #0043ea;

    padding: 0 0 5px 0;

    @media (max-width: 1279px) {
      border-width: 0.07em;
    }

    & h3 {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.075em;
    }

    & .item-header {
      grid-column: 1 / 6;
    }

    & .quantity-header {
      grid-column: 6 / 9;
    }

    & .price-header {
      grid-column: 9 / 11;
    }
  }

  & .outer-row {
  }

  & .item {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 20px;

    padding: 27px 0 29px 0;

    /* border-bottom: 1px solid #f53b07; */
    border-bottom: 1px solid #0043ea;

    @media (max-width: 1279px) {
      border-width: 0.07em;
    }

    & .item-details {
      grid-column: 1 / 6;

      & h3 {
        font-size: 22px;
        line-height: 28px;
        letter-spacing: 0.025em;
      }
    }

    & .item-quantity {
      grid-column: 6 / 9;

      & .quantity-buttons-container {
        display: grid;
        grid-template-columns: 25px 30px 25px;

        & .value {
          text-align: center;
        }
      }
    }

    & .item-price {
      grid-column: 9 / 11;

      display: grid;
      grid-template-columns: auto 30px;
      grid-column-gap: 10px;

      & .remove-button {
        font-size: 16px;
      }
    }

    & button {
      color: #0043ea;
      // font-size: 15px;
    }
  }

  & .totals {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    grid-column-gap: 20px;

    & .container {
      margin: 40px 0;
      grid-column: 6 / 11;

      & .inner-grid-container {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 20px;

        & .column {
          &.left {
            grid-column: 1 / 4;

            & h3 {
              font-size: 18px;
              line-height: 28px;
              letter-spacing: 0.075em;
            }
          }

          &.right {
            grid-column: 4 / 6;

            & p {
              font-size: 18px;
              line-height: 28px;
              letter-spacing: 0.075em;

              margin: 0;

              &.total-price {
                font-size: 22px;
                line-height: 28px;
                letter-spacing: 0.025em;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & .header-row {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;

      & .item-header {
        grid-column: 1 / 5;
      }

      & .quantity-header {
        display: none;
      }
      & .price-header {
        grid-column: 5 / 7;
      }

      & h3 {
        font-size: 11.5px;
        line-height: 15px;
      }
    }

    & .outer-row {
      & .item {
        grid-template-columns: repeat(6, 1fr);
        grid-auto-flow: dense;

        padding: 17px 0 18px 0;

        & .item-details,
        & .item-quantity {
          grid-column: 1 / 5;
        }

        & .item-price {
          grid-column: 5 / 7;
        }

        & h3,
        & button,
        & .remove-button {
          font-size: 14px;
          line-height: 18px;
        }
      }

      & .totals {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;

        & .container {
          margin: 20px 0;
          grid-column: unset;

          & .inner-grid-container {
            grid-template-columns: repeat(6, 1fr);
            & .column {
              &.left {
                grid-column: 1 / 5;

                & h3 {
                  font-size: 11.5px;
                  line-height: 18px;
                }
              }

              &.right {
                grid-column: 5 / 7;

                & p {
                  font-size: 14px;
                  line-height: 18px;
                }
              }
            }
          }
        }
      }
    }
  }
`;

const Cart = () => {
  const { checkout, loading } = useContext(StoreContext);
  const emptyCart = checkout.lineItems.length === 0;

  const height = use100vh();

  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageColor: `#DDE1D4`,
      pageType: `cart`,
    });
  }, []);

  return (
    <>
      {/* <PageSeo seoTitle="Cart | RAW Editions" seoImage={null} seoText={null} /> */}

      <Page height={height}>
        <div className={`page-container ${emptyCart ? `cart-is-empty` : ``}`}>
          {emptyCart ? (
            <div className="empty-cart center-text">
              <p>Your cart is currently empty</p>
            </div>
          ) : (
            <div className="table-container">
              <Table>
                <div className="header-row">
                  <div className="item-header">
                    <h3 className="uppercase">Item</h3>
                  </div>

                  <div className="quantity-header">
                    <h3 className="uppercase">Quantity</h3>
                  </div>

                  <div className="price-header">
                    <h3 className="uppercase">Price</h3>
                  </div>
                </div>

                <div className="outer-row">
                  {checkout.lineItems.map(item => (
                    <LineItem item={item} key={item.id} />
                  ))}
                </div>

                <div className="outer-row">
                  <div className="row totals">
                    <div className="container">
                      <div className="inner-grid-container line">
                        <div className="column left">
                          <h3 className="uppercase">Subtotal</h3>
                        </div>
                        <div className="column right">
                          <p className="total-price">
                            {formatPrice(
                              checkout.subtotalPriceV2.currencyCode,
                              checkout.subtotalPriceV2.amount
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="inner-grid-container line">
                        <div className="column left">
                          <h3 className="uppercase">Delivery</h3>
                        </div>
                        <div className="column right">
                          <p className="total-price">Calculated at Checkout</p>
                        </div>
                      </div>

                      <div className="buttons-container">
                        <div className="inner-grid-container line">
                          <div className="column left" />

                          <div className="column right">
                            <Link to={`/shop/`} className="uppercase">
                              Continue Shopping
                            </Link>
                            <a href={checkout.webUrl} className="uppercase">
                              Check Out
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Table>
            </div>
          )}
        </div>
      </Page>
    </>
  );
};

export default Cart;
