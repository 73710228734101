exports.linkResolver = doc => {
  switch (doc.type) {
    case "homepage": {
      return `/`;
    }

    case "about": {
      return `/${doc.uid}/`;
    }

    case "work": {
      return `/${doc.uid}/`;
    }

    case "jobs": {
      return `/${doc.uid}/`;
    }

    case "job_listing": {
      return `/jobs/${doc.uid}/`;
    }

    case "the_fund": {
      return `/${doc.uid}/`;
    }

    case "contact": {
      return `/${doc.uid}/`;
    }

    case "news": {
      return `/${doc.uid}/`;
    }

    case "shop": {
      return `/${doc.uid}/`;
    }

    case "product": {
      return `/${doc.uid}/`;
    }

    case "project": {
      return `/work/${doc.uid}/`;
    }

    case "privacy_policy": {
      return `/${doc.uid}/`;
    }
  }
};
