import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-setup";
import { StoreContext } from "../components/context/store-context";

// Components
import { SingleProduct } from "../components/product/single-product";

const Page = styled.div`
  padding: 160px 0 230px 0;

  @media (max-width: 1279px) {
    padding: 125px 0 150px 0;
  }

  & .introduction-container {
    padding: 0 30px;
    margin: 0 0 150px 0;

    & p {
      margin: 35px 0 0 0;
    }
  }

  & .products-container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 90px;

    & .single-product {
      &:nth-of-type(5n + 1) {
        grid-column: 1 / 7;

        & .product-title {
          padding: 0 30px;
        }
      }
      &:nth-of-type(5n + 2) {
        grid-column: 8 / 12;
        margin: 320px 0 0 0;
      }
      &:nth-of-type(5n + 3) {
        grid-column: 2 / 6;
      }
      &:nth-of-type(5n + 4) {
        grid-column: 7 / 13;
        margin: 290px 0 0 0;
      }

      &:nth-of-type(5n + 5) {
        grid-column: 4 / 8;
        margin: 0;
      }
    }
  }

  & .cart-banner {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    padding: 0 30px;
    z-index: 100;

    height: 70px;
    line-height: 70px;
    background-color: #e4edf5;

    & a {
      display: block;

      width: fit-content;
      height: 31px;
      padding: 0 10px;

      /* border: 1px solid #f53b07; */
      border: 1px solid #0043ea;

      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.075em;
      color: #0043ea;

      transition: 300ms background ease-in, 300ms color ease-in;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: rgba(245, 59, 7, 0.8);
          color: #e4edf5;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & .introduction-container {
      margin: 0 0 75px 0;

      & p {
        margin: 25px 0 0 0;
      }
    }
  }

  @media (max-width: 768px) {
    & .introduction-container {
      padding: 0 20px;
    }

    & .products-container {
      grid-template-columns: repeat(6, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 60px;

      & .single-product {
        &:nth-of-type(5n + 1) {
          grid-column: 1 / 6;

          & .product-title {
            padding: 0 20px;
          }
        }

        &:nth-of-type(5n + 2) {
          grid-column: 3 / 7;
          margin: 0;
        }

        &:nth-of-type(5n + 3) {
          grid-column: 1 / 4;

          & .product-title {
            padding: 0 20px;
          }
        }

        &:nth-of-type(5n + 4) {
          grid-column: 2 / 7;
          margin: 0;
        }

        &:nth-of-type(5n + 5) {
          grid-column: 3 / 7;
          margin: 0;
        }

        & .product-title {
          & h3 {
            font-size: 9.5px;
            line-height: 12px;
            letter-spacing: 0.025em;
          }
        }
      }
    }
  }
`;

const Shop = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Cart
  const { checkout, loading, didJustAddToCart } = useContext(StoreContext);
  const items = checkout ? checkout.lineItems : [];
  const quantity = items.reduce((total, item) => {
    return total + item.quantity;
  }, 0);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    setPageSetup({
      pageType: `shop`,
    });
  }, []);

  const products = data.shopifyCollection.products.map((product, index) => (
    <SingleProduct
      product={product}
      index={index}
      key={`single_product_${index}_${data.shopifyCollection.id}`}
    />
  ));

  return (
    <Page>
      <section className="introduction-container center-text">
        <h1 className="uppercase glossy-display">
          {data.shopifyCollection.title}
        </h1>
        <p>{data.shopifyCollection.description}</p>
      </section>

      <section className="products-container">{products}</section>
    </Page>
  );
};

export const query = graphql`
  query {
    shopifyCollection(title: { eq: "Everyday Wares" }) {
      title
      description
      id
      products {
        featuredImage {
          gatsbyImageData
          altText
        }
        title
        handle
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            currencyCode
            amount
          }
        }
        variants {
          price
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(Shop);
