import React from "react";
import styled from "styled-components";
import { useSelect } from "downshift";

// Components
import { DropdownIcon } from "../icons/dropdown-icon";

const DropDownContainer = styled.div`
  position: relative;

  max-width: 400px;
  width: 100%;

  font-size: 15px;
  line-height: 19px;
  letter-spacing: 0.025em;
  color: #0043ea;

  & .dropdown-container-label {
    display: grid;
    grid-template-columns: 50px 200px;
    grid-column-gap: 10px;
    align-items: center;

    & p {
      margin: 0;
    }
  }

  & button[aria-expanded="true"] + ul {
    border: 1px solid #000;
    padding: 10px 0;
  }

  @media (max-width: 1279px) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 600px) {
    width: 100%;
  }
`;
const DropDownHeader = styled.button`
  min-width: 115px;

  color: #0043ea;
  text-align: left;

  & svg {
    margin: 0 0 0 10px;
  }
`;

const DropDownList = styled.ol`
  position: absolute;
  top: 24px;
  left: 0;
  right: 0;

  z-index: 1;

  max-height: 280px;
  max-width: 150px;
  width: 100%;
  overflow-y: auto;

  background-color: #fff;

  &.open {
    border: 1px solid #0043ea;
    /* border: 1px solid #f53b07; */
  }

  list-style: none;

  margin: 0;
  padding: 0;
`;

const DropDownListItem = styled.li`
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.025em;

  padding: 5px 10px;

  cursor: pointer;
  color: #0043ea;

  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 4px;
  text-decoration-color: ${props => (props.ishighlighted ? "#f53b07" : "#fff")};

  text-align: left;

  @media (max-width: 1279px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

export const VariantSelectors = ({
  handleOptionChange,
  items,
  name,
  index,
}) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    onSelectedItemChange: ({ selectedItem }) => {
      handleOptionChange(index, selectedItem);
    },
  });

  return (
    <DropDownContainer>
      <div className="dropdown-container-label">
        <p>{name}</p>
        <DropDownHeader
          {...getToggleButtonProps()}
          type="button"
          aria-label={`Select a ${name}`}
        >
          {(selectedItem && selectedItem) || items[0]}
          <DropdownIcon isOpen={isOpen} />
        </DropDownHeader>
      </div>

      <DropDownList
        {...getMenuProps()}
        aria-label={`Select a ${name}`}
        className={isOpen ? `open` : `closed`}
      >
        {isOpen &&
          items.map((value, index) => (
            <DropDownListItem
              ishighlighted={highlightedIndex === index}
              key={`${name}-${value}`}
              {...getItemProps({ value, index })}
            >
              {value}
            </DropDownListItem>
          ))}
      </DropDownList>
    </DropDownContainer>
  );
};
