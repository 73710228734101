import React from "react";

export const DropdownIcon = () => (
  <svg
    width="13"
    height="7"
    viewBox="0 0 13 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 6.01168L0.497379 0L0 0.497566L6.00943 6.50243L6.5 7L6.99738 6.50243L8.25786 5.23466L9.06866 4.43038L13 0.497566L12.5094 0L6.5 6.01168Z"
      fill="#0043ea"
    />
  </svg>
);
