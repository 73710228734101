import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  max-height: calc(${props => props.height}px - 150px - 150px);

  @media (max-width: 768px) {
    max-height: unset;
    padding: 0 20px;

    & .aspect-ratio-container {
      width: 100%;
    }
  }

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-media-container {
    position: relative;

    width: 100%;
    height: 100%;
    max-height: calc(${props => props.height}px - 150px - 150px);

    @media (max-width: 768px) {
      max-height: unset;
    }
  }

  & img {
    width: 100%;
    height: 100%;
    max-height: calc(${props => props.height}px - 150px - 150px);

    @media (max-width: 768px) {
      max-height: unset;
    }
  }

  & .react-slideshow-container + ul.indicators {
    margin-top: 10px;
  }

  & .single-media-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    & .gatsby-image-wrapper {
      width: 100%;
      height: 100%;

      & img {
        object-fit: contain !important;
      }
    }
  }

  @media (max-width: 768px) {
    & .single-image-container {
      & .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }

      &.landscape {
        & img {
          object-fit: cover !important;
        }
      }
    }
  }
`;

export const Gallery = ({ media }) => {
  const height = use100vh();

  return (
    <GalleryContainer height={height}>
      <Slide
        arrows={false}
        transitionDuration={300}
        pauseOnHover={false}
        autoplay={false}
        infinite={true}
        canSwipe={media.length > 1 ? true : false}
        defaultIndex={0}
        indicators={false}
      >
        {media}
      </Slide>
    </GalleryContainer>
  );
};
