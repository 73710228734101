import React, { useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import "external-svg-loader";

// Components
import { FooterNewsletter } from "../forms/footer-newsletter";

// Icons
import { InstagramIcon, TwitterIcon } from "../icons/social-icons";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px;

  line-height: 75px;

  &.page-type-shop,
  &.page-type-product,
  &.page-type-cart {
    padding: 0 20px 65px 20px;

    & .cart-link {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding: 0 20px;
    }
  }

  & .footer-links-container {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: flex-end;

    width: 100%;

    & .social-links-container {
      justify-content: flex-end;

      & li {
        margin: 0 0 0 25px;
        line-height: 1;

        & a {
          line-height: 1;
        }
      }
    }
  }

  & ol {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & li {
      & a,
      & button {
        font-size: 11.5px;
        line-height: 25px;
        letter-spacing: 0.075em;
        text-transform: uppercase;
      }

      margin: 0 40px 0 0;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  & .cart-link {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    height: 60px;
    line-height: 60px;

    & a {
      display: block;
      width: fit-content;
      height: 31px;

      padding: 0 10px;
      /* border: 0.05em solid #f53b07; */

      font-size: 16px;
      line-height: 30px;

      letter-spacing: 0.075em;
      color: #0043ea;

      transition: 300ms background ease-in, 300ms color ease-in;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background: rgba(245, 59, 7, 0.8);
          color: #e4edf5;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 20px;
    line-height: 25px;

    & .main-footer-menu {
      display: block;

      & li {
        & a,
        & button {
          line-height: 25px;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & ol {
      & li {
        & a,
        & button {
          font-size: 11.5px;
          line-height: 15px;
          letter-spacing: 0.075em;
        }
      }
    }

    & .cart-link {
      & li {
        & a {
          font-size: 10px;
          line-height: 19px;

          height: 20px;

          border: 0.07em solid;
        }
      }
    }
  }
`;

export const MobileFooter = ({ quantity, location, pageSetup, data }) => {
  const [isNewsletterVisible, setIsNewsletterVisible] = useState(false);

  const links = data.prismicMenus.data.footer_social_links
    .filter(link => link.visible_on_website)
    .map((link, index) => (
      <li key={`single_mobile_external_link_${index}`}>
        <a
          href={link.link.url}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={link.website}
        >
          <svg data-src={link.svg_logo.url} />
        </a>
      </li>
    ));

  return (
    <>
      <FooterWrapper className={`page-type-${pageSetup.pageType}`}>
        <div className="footer-links-container">
          <ol className="main-footer-menu">
            <li>
              <Link to={`/contact/`}>Contact</Link>
            </li>

            <li>
              <button onClick={() => setIsNewsletterVisible(true)}>
                THE NEWLETTER
              </button>
            </li>

            <li>
              <Link to={`/privacy-policy/`}>Terms & Legal</Link>
            </li>
          </ol>

          <ol className="social-links-container">{links}</ol>
        </div>

        <ol className="cart-link">
          <li>
            <Link to={`/cart/`} className="uppercase">
              Cart {quantity >= 1 ? `(${quantity})` : ``}
            </Link>
          </li>
        </ol>

        <FooterNewsletter
          isOpen={isNewsletterVisible}
          setIsNewsletterVisible={setIsNewsletterVisible}
          pageType={pageSetup.pageType}
        />
      </FooterWrapper>
    </>
  );
};
