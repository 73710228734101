import React, { useEffect, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";
import { LinkArrow } from "../components/icons/link-arrow";

// Component
import { HomepageText } from "../components/homepage/homepage-text";

const Page = styled.div`
  padding: 160px 0 0 0;

  @media (max-width: 1279px) {
    padding: 125px 0 0 0;
  }

  & section {
    padding: 0 30px;

    @media (max-width: 1279px) {
      padding: 0 20px;
    }

    &.top-section {
      & .about-text {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-column-gap: 20px;
        margin: 0 0 140px 0;

        & p {
          max-width: 560px;
          grid-column: 2 / 13;

          margin: 0;
        }

        @media (max-width: 1439px) {
          margin: 0 0 110px 0;
        }

        @media (max-width: 1279px) {
          grid-template-columns: repeat(6, 1fr);
          margin: 0 0 70px 0;
          padding: 0 0 0 100px;

          & p {
            grid-column: 1 / 6;
            max-width: 420px;
          }
        }

        @media (max-width: 768px) {
          padding: 0;
        }

        @media (max-width: 500px) {
          & p {
            max-width: 295px;
          }
        }
      }
    }
    &.bottom-section {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-column-gap: 20px;
      align-items: baseline;

      background: #b6d8d0;

      padding: 135px 30px;

      @media (max-width: 1439px) {
        padding: 110px 30px;
      }

      @media (max-width: 1279px) {
        padding: 80px 30px;
      }

      & .column {
        & .container {
          max-width: 330px;
          margin: 0 auto;
        }

        & .link-arrow-container {
          margin: 0 0 0 15px;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            & .stem {
              width: 80px;

              @media (max-width: 1279px) {
                width: 40px;
              }
            }
          }
        }

        & h2 {
          font-size: 18px;
          line-height: 23px;
          text-align: center;
          letter-spacing: 0.075em;

          margin: 0 0 25px 0;

          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;

          /* & svg {
            margin: 0 0 0 15px;
          } */

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;

            margin: 0 0 10px 0;
          }
        }

        & .container {
          & p {
            font-size: 35px;
            line-height: 45px;
            letter-spacing: 0.075em;

            margin: 0;

            @media (max-width: 1279px) {
              font-size: 22px;
              line-height: 29px;
            }
          }
        }
      }

      @media (max-width: 1279px) {
        display: block;
        grid-template-columns: unset;
        grid-column-gap: unset;
        align-items: unset;

        padding: 75px 20px;

        & .column {
          margin: 0 0 75px 0;

          &:last-of-type {
            margin: 0;
          }

          & .container {
            max-width: 340px;
            margin: 0 auto;
          }
        }
      }

      @media (max-width: 1279px) {
        & .column {
          & .container {
            max-width: 200px;
          }
        }
      }
    }
  }
`;

const Index = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageType: `home`,
    });
  }, []);

  return (
    <Page>
      <section className="top-section">
        <div className="about-text">
          <PrismicRichText field={data.prismicHomepage.data.text.richText} />
        </div>

        <HomepageText />
      </section>

      <section className="bottom-section">
        <div className="column">
          <h2 className="uppercase">
            The Latest News <LinkArrow fill={`#0043EA`} />
          </h2>

          <div className="container center-text uppercase glossy-display">
            <PrismicRichText
              field={data.prismicHomepage.data.latest_news.richText}
            />
          </div>
        </div>

        <div className="column">
          <h2 className="uppercase">
            Our Latest Project <LinkArrow fill={`#0043EA`} />
          </h2>
          <div className="container center-text uppercase glossy-display">
            <PrismicRichText
              field={data.prismicHomepage.data.latest_project.richText}
            />
          </div>
        </div>

        <div className="column">
          <h2 className="uppercase">
            The Fund <LinkArrow fill={`#0043EA`} />
          </h2>

          <div className="container center-text uppercase glossy-display">
            <PrismicRichText
              field={data.prismicHomepage.data.the_fund.richText}
            />
          </div>
        </div>
      </section>
    </Page>
  );
};

export default withPrismicPreviewResolver(Index);

export const query = graphql`
  {
    prismicHomepage {
      _previewable
      data {
        text {
          richText
        }
        latest_project {
          richText
        }
        latest_news {
          richText
        }
        the_fund {
          richText
        }
      }
    }
  }
`;
