import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import { RemoveScroll } from "react-remove-scroll";

// Slideshow
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const GalleryContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: calc(${props => props.height}px - 100px);
  height: 100%;

  @media (max-width: 1279px) {
    max-height: calc(${props => props.height}px - 50px);
  }

  & > div,
  & .react-slideshow-container,
  & .react-slideshow-fade-wrapper,
  & .react-slideshow-fade-images-wrap,
  & .react-slideshow-fade-images-wrap > div,
  & .single-article-container {
    position: relative;
    display: block;
    /* width: 100%; */
    height: 100%;
  }

  & .react-slideshow-container {
    display: flex;
  }

  & .single-article-container {
    & a {
      display: block;
    }
  }

  & .react-slideshow-container + ul.indicators {
    margin-top: 10px;
  }

  & .caption {
    height: auto;
    text-align: center;
  }
`;

export const NewsGallery = ({ articles }) => {
  const height = use100vh();

  // Slideshow Reference
  const slideRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(null);

  useEffect(() => {
    setTotalSlides(articles.length);
  }, []);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <RemoveScroll className="remove-scroll">
      <GalleryContainer height={height}>
        <Slide
          ref={slideRef}
          onChange={(previous, next) => updateCurrentSlide(next)}
          arrows={false}
          transitionDuration={300}
          pauseOnHover={false}
          autoplay={false}
          infinite={true}
          canSwipe={articles.length > 1 ? true : false}
          defaultIndex={0}
          indicators={false}
        >
          {articles}
        </Slide>

        <div className="caption">
          <p>
            {currentSlide + 1} / {totalSlides}
          </p>
        </div>
      </GalleryContainer>
    </RemoveScroll>
  );
};
