import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Icon = styled.div`
  padding: 12px 0 0 0;

  & svg {
    display: block;

    fill: inherit;

    width: 215px;
    height: auto;
  }

  @media (max-width: 1439px) {
    & svg {
      width: 190px;
    }
  }

  @media (max-width: 1279px) {
    padding: 0;
  }

  @media (max-width: 500px) {
    & svg {
      width: 160px;
    }
  }
`;

export const Logo = () => (
  <Icon>
    <Link to={`/`} aria-label="Home">
      <svg
        width="766"
        height="138"
        viewBox="0 0 766 138"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M126.976 74.5212C132.674 56.1168 147.761 54.6961 152.228 54.6961C162.912 54.6961 169.258 59.1519 169.258 66.6428C169.258 71.6152 165.891 74.5212 159.998 74.5212H126.976ZM182.467 113.396C175.668 125.86 167.25 131.672 156.113 131.672C140.962 131.672 124.709 120.952 124.709 90.8591C124.709 85.7575 125.227 81.0434 126.199 76.7814H181.625L181.56 76.5231C179.747 62.4453 168.351 52.2422 154.559 52.2422C145.106 52.2422 135.652 56.2459 128.724 63.2848C123.026 69.0322 116.227 79.5582 116.227 97.1876C116.227 109.78 119.724 120.306 126.458 127.539C132.609 134.255 141.35 137.936 151.063 137.936C166.085 137.936 178.323 128.959 183.762 113.978L183.827 113.784L182.596 113.203L182.467 113.396Z" />
        <path d="M300.701 54.5676C299.794 54.6968 299.017 54.7614 297.852 54.7614C296.557 54.7614 295.65 54.6968 294.42 54.5676C292.413 54.3739 290.017 54.1802 284.837 54.1802H284.578V56.2466H284.837C291.377 56.2466 293.837 56.6341 294.744 57.7965C295.521 58.7651 295.068 60.5087 294.291 63.2855L277.844 120.953L259.066 63.4792C258.16 60.8316 258.16 58.8943 259.066 57.7319C260.426 55.9883 263.664 56.1175 266.319 56.2466C266.966 56.3112 267.614 56.3112 268.132 56.3112H268.391V54.1802H265.606C260.75 54.1802 257.901 54.4385 255.829 54.5676C254.728 54.6968 253.822 54.7614 252.98 54.7614C252.073 54.7614 251.361 54.6968 250.519 54.5676C249.03 54.3739 246.893 54.1802 242.102 54.1802H239.318V56.2466H239.577C245.145 56.2466 247.282 56.8278 249.613 63.8021L253.109 73.8115L238.605 120.953L219.633 63.4792C218.662 60.5087 218.597 58.8297 219.31 57.7965C220.216 56.5049 222.677 56.2466 226.562 56.2466H226.821V54.1802H226.562C221.058 54.1802 218.597 54.3739 216.655 54.5676C215.425 54.6968 214.518 54.7614 213.158 54.7614C211.993 54.7614 210.892 54.6968 209.532 54.5676C207.331 54.3739 204.352 54.1802 199.172 54.1802H198.913V56.2466H199.172C204.935 56.2466 207.914 56.957 210.18 63.8021L234.461 135.805L234.526 135.999H236.469L254.34 77.7507L274.089 135.805L274.153 135.999H276.096L296.751 63.8021C298.888 56.957 300.118 56.2466 310.09 56.2466H310.349V54.1802H310.09C304.327 54.1802 302.255 54.4385 300.701 54.5676Z" />
        <path d="M84.8879 0.387461C83.7224 0.516614 82.7511 0.581191 81.1971 0.581191C79.6431 0.581191 78.6718 0.516614 77.4416 0.387461C75.3696 0.258307 72.5205 0 64.6857 0H64.4267V2.06646H64.6857C78.4776 2.06646 79.7726 2.26019 79.7726 9.55736V117.207L11.1371 0.129154L11.0723 0H0L16.7056 28.4138V126.506C16.7056 133.739 15.2164 133.997 1.42451 133.997H1.16551V136.063H1.42451C8.80607 136.063 11.7846 135.87 13.9861 135.676C15.2811 135.611 16.3171 135.482 17.9359 135.482C19.6841 135.482 20.9144 135.547 22.4684 135.676C24.8642 135.87 27.8427 136.063 34.1883 136.063H34.4473V133.997H34.253C20.6554 133.997 19.3604 133.803 19.3604 126.506V32.9342L79.8373 135.87L79.9021 135.999H82.6216V9.55736C82.6216 2.32476 84.3051 2.06646 97.7085 2.06646H97.9675V0H97.7085C89.6794 0 86.8951 0.258307 84.8879 0.387461Z" />
        <path d="M669.585 126.506V78.8479C669.585 60.4435 664.275 52.2422 652.426 52.2422C644.591 52.2422 635.202 57.7313 628.274 66.2554C626.008 56.698 620.893 52.2422 612.216 52.2422C603.604 52.2422 593.762 58.1833 586.834 67.4178V50.4341L567.214 64.5118L567.02 64.641L567.862 66.0617L568.056 65.8679C573.172 62.0579 575.891 60.056 577.186 60.056C577.316 60.056 577.445 60.056 577.575 60.1206C578.352 60.5081 578.352 62.7037 578.352 65.9971V127.926C578.352 133.674 576.862 133.867 567.927 133.867H567.668V135.934H567.927C573.236 135.934 576.344 135.675 578.611 135.546C580.035 135.417 581.136 135.353 582.237 135.353C583.532 135.353 584.632 135.417 586.057 135.546C588.194 135.74 591.172 135.934 596.805 135.934H597.064V133.867H596.805C588.194 133.867 586.769 133.738 586.769 127.926V70.5175C592.661 62.6391 600.043 58.1187 607.101 58.1187C616.749 58.1187 620.828 64.3181 620.828 78.7833V126.441C620.828 133.674 619.662 133.932 610.209 133.932H609.95V135.998H610.209C615.713 135.998 618.821 135.74 621.152 135.611C622.511 135.482 623.612 135.417 624.778 135.417C626.137 135.417 627.173 135.482 628.598 135.611C630.735 135.805 633.713 135.998 639.346 135.998H639.605V133.932H639.346C630.476 133.932 629.31 133.674 629.31 126.635V78.8479C629.31 74.4566 629.116 71.357 628.598 68.451C635.591 59.9915 643.102 58.1833 648.088 58.1833C656.829 58.1833 661.038 64.9639 661.038 78.8479V126.506C661.038 133.738 660.002 133.996 650.613 133.996H650.354V136.063H650.613C655.923 136.063 659.031 135.805 661.362 135.675C662.721 135.546 663.822 135.482 664.988 135.482C666.347 135.482 667.448 135.546 668.873 135.675C671.074 135.869 674.118 136.063 679.751 136.063H680.01V133.996H679.751C670.492 133.932 669.585 133.738 669.585 126.506Z" />
        <path d="M528.753 92.0861L528.558 118.692C521.824 128.766 513.471 130.897 507.644 130.897C494.629 130.897 491.78 121.016 491.78 117.206C491.78 110.878 498.449 107.52 508.55 102.418C509.975 101.708 511.464 100.998 512.953 100.158L528.753 92.0861ZM537.041 127.991L537.235 75.7481C537.235 60.508 527.004 52.2422 517.356 52.2422C504.471 52.2422 479.995 67.8698 479.995 76.1356C479.995 78.977 482.197 81.0434 485.305 81.0434C488.024 81.0434 491.003 79.2999 491.003 74.392V68.451C492.104 66.4491 497.867 57.2146 510.946 57.2146C522.731 57.2146 528.753 64.6409 528.753 79.2353V89.1801L512.047 97.6397C487.183 110.103 483.168 113.332 483.168 120.5C483.168 124.697 484.722 128.895 487.506 131.93C490.938 135.74 496.118 137.806 502.464 137.806C512.759 137.806 521.306 132.769 528.623 122.308V136.063H528.882C529.853 136.063 530.63 135.934 531.407 135.805C532.379 135.675 533.479 135.482 535.292 135.482C536.458 135.482 537.364 135.546 538.595 135.611C540.796 135.805 544.163 136.063 551.804 136.063H552.063V133.996H551.804C537.559 133.932 537.041 133.738 537.041 127.991Z" />
        <path d="M708.694 74.5212C714.392 56.1168 729.479 54.6961 733.947 54.6961C744.631 54.6961 750.976 59.1519 750.976 66.6428C750.976 71.6152 747.609 74.5212 741.717 74.5212H708.694ZM764.38 113.138L764.25 113.332C757.581 125.601 748.904 131.607 737.897 131.607C722.745 131.607 706.493 120.887 706.493 90.7945C706.493 85.693 707.011 80.9789 707.982 76.7168H763.409L763.344 76.4585C761.466 62.4453 750.07 52.2422 736.278 52.2422C726.825 52.2422 717.371 56.2459 710.443 63.2848C704.745 69.0322 697.946 79.6227 697.946 97.2522C697.946 109.845 701.442 120.371 708.176 127.603C714.328 134.319 723.069 138 732.782 138C747.804 138 759.977 129.024 765.416 114.042L765.481 113.848L764.38 113.138Z" />
        <path d="M450.598 0.387461C449.433 0.516614 448.461 0.581191 446.907 0.581191C445.353 0.581191 444.382 0.516614 443.152 0.387461C441.08 0.258307 438.231 0 430.396 0H430.137V2.06646H430.396C444.188 2.06646 445.483 2.26019 445.483 9.55736V117.207L376.848 0.129154L376.783 0H365.71L382.416 28.4138V126.506C382.416 133.739 380.927 133.997 367.135 133.997H366.876V136.063H367.135C374.517 136.063 377.495 135.87 379.697 135.676C380.992 135.611 382.028 135.482 383.646 135.482C385.395 135.482 386.625 135.547 388.244 135.676C390.639 135.87 393.618 136.063 399.963 136.063H400.222V133.997H399.963C386.366 133.997 385.071 133.803 385.071 126.506V32.9342L445.548 135.87L445.612 135.999H448.332V9.55736C448.332 2.32476 450.016 2.06646 463.419 2.06646H463.678V0H463.419C455.39 0 452.606 0.258307 450.598 0.387461Z" />
      </svg>
    </Link>
  </Icon>
);
