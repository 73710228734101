import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import addToMailchimp from "gatsby-plugin-mailchimp";

// Components
import { CountrySelector } from "./country-selector";

const EnquiryFormContainer = styled.div`
  position: relative;
  align-self: flex-end;

  & .signup-text {
    margin: 0 0 28px 0;

    & p {
      margin: 0;
    }
  }

  & .thank-you-message,
  & form {
    margin: 0;

    & .grid {
      &.grid-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;

        @media (max-width: 1279px) {
          display: block;
          grid-template-columns: unset;
          grid-column-gap: unset;
        }
      }
    }

    & .field {
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: baseline;

      border-bottom: 1px solid #0043ea;

      margin: 0 0 15px 0;

      @media (max-width: 1279px) {
        border-width: 0.05em;
      }

      & input {
        width: 100%;
        padding: 0;
        margin: 0;
        border: 0;

        background-color: transparent;

        font-size: 22px;
        line-height: 28px;
        letter-spacing: 0.025em;

        color: #0043ea;

        @media (max-width: 1279px) {
          font-size: 14px;
          line-height: 18px;
        }

        ::-webkit-input-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
        ::-moz-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
        :-ms-input-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
        :-moz-placeholder {
          font-family: "FoundersGroteskText", "Helvetica Neue", "Lucida Grande",
            sans-serif;
          color: #0043ea;
          text-transform: uppercase;

          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0.075em;

          @media (max-width: 1279px) {
            font-size: 11.5px;
            line-height: 15px;
          }
        }
      }
    }
  }

  & .thank-you-message {
    line-height: 15px;

    & p {
      margin: 0;
    }
  }
`;

const OptionsContainer = styled.div`
  max-width: 670px;
  width: 100%;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  font-size: 19px;
  line-height: 25px;
  letter-spacing: 0.02em;

  & #country {
    width: calc(100% - 230px);
  }

  @media (max-width: 1279px) {
    & #country {
      width: calc(100% - 100px);
    }

    font-size: 13px;
    line-height: 21px;
  }
`;

const SubmitButtonContainer = styled.div`
  & .top-line-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: 1279px) {
      justify-content: flex-end;
    }

    margin: 30px 0 0 0;

    & p {
      margin: 0;
    }

    @media (max-width: 1279px) {
      margin: 20px 0 0 0;
    }

    & button {
      text-align: center;
      text-transform: uppercase;
      font-size: 16px;
      line-height: 27px;
      letter-spacing: 0.075em;

      padding: 0 10px 1px 10px;
      margin: 0 0 0 auto;
      height: 32px;

      border: 1px solid #0043ea;
      color: #0043ea;

      transition: 300ms background ease-in, 300ms color ease-in;

      @media (max-width: 1279px) {
        font-size: 10px;
        line-height: 13px;
        height: 20px;

        padding: 0 8px;
        margin: 0;

        border-width: 0.07em;
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover,
        &:focus,
        &:focus-within {
          color: #ebe6fd;
          background-color: #0043ea;
        }
      }

      /* &:disabled,
      &[disabled] {
        &:hover,
        &:focus,
        &:focus-within {
          color: #000;
          background-color: transparent;

          -webkit-text-stroke: 0.1px #000;
        }
      } */
    }
  }
`;

export const SignUp = ({ pageType, isNewsletter }) => {
  const [isFormVisible, setIsFormVisible] = useState(true);
  const [formMessage, setFormMessage] = useState(null);
  const [mailchimpMessage, setMailchimpMessage] = useState(null);

  const data = useStaticQuery(graphql`
    {
      prismicHomepage {
        data {
          newsletter_text {
            html
          }
          newsletter_confirmation_text {
            html
          }
        }
      }
    }
  `);

  // Form Setup
  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    setValue,
    formState,
    getValues,
  } = useForm();

  const onSubmit = (data, e) => {
    e.preventDefault();

    addToMailchimp(data.email, {
      FNAME: data.fName,
      LNAME: data.lName,
      COUNTRY: data.country,
    }) // listFields are optional if you are only capturing the email address.
      .then(data => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        if (data.result === "error") {
          if (data.msg === "The email you entered is not valid.") {
            setFormMessage("Invalid Email");
          }

          if (data.msg.includes("already subscribed")) {
            setMailchimpMessage(
              "Thank you for signing up to the New Name mailing list."
            );
            setIsFormVisible(false);
            e.target.reset();
          }
        } else {
          setMailchimpMessage(
            "Thank you for signing up to the New Name mailing list."
          );
          setIsFormVisible(false);
          e.target.reset();
        }
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    if (isFormVisible === false) {
      const fadeOutForm = setTimeout(() => {
        const resetFormVisbility = setTimeout(() => {
          setIsFormVisible(true);
        }, 1000);
        return () => clearTimeout(resetFormVisbility);
      }, 8000);
      return () => clearTimeout(fadeOutForm);
    }
  }, [isFormVisible]);

  return (
    <EnquiryFormContainer className="z-index-100">
      {isFormVisible && (
        <>
          <form
            name="signup-form"
            id={`${
              isNewsletter ? `newsletter-` : ``
            }${pageType}-holding_site_signup_form`}
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={handleSubmit(onSubmit)}
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="signup-form" />

            <div className="grid grid-2">
              <div className="field">
                <input
                  id={`${
                    isNewsletter ? `newsletter-` : ``
                  }${pageType}-signup-form-fName`}
                  name="fName"
                  type="text"
                  autoComplete="given-name"
                  aria-required="true"
                  aria-label="First Name"
                  placeholder={
                    errors.fName ? `This field is required` : `First Name`
                  }
                  ref={register({
                    required: true,
                  })}
                />
              </div>

              <div className="field">
                <input
                  id={`${
                    isNewsletter ? `newsletter-` : ``
                  }${pageType}-signup-form-lName`}
                  name="lName"
                  type="text"
                  autoComplete="family-name"
                  aria-required="true"
                  aria-label="Last Name"
                  placeholder={
                    errors.lName ? `This field is required` : `Last Name`
                  }
                  ref={register({
                    required: true,
                  })}
                />
              </div>
            </div>

            <div className="field">
              <input
                id={`${
                  isNewsletter ? `newsletter-` : ``
                }${pageType}-signup-form-email`}
                name="email"
                type="email"
                autoComplete="email"
                aria-required="true"
                aria-label="Email"
                placeholder={
                  errors.email ? `Enter a valid email address` : `Email`
                }
                ref={register({
                  required: true,
                })}
              />
            </div>

            <div className="field">
              <OptionsContainer>
                <Controller
                  control={control}
                  name="country"
                  defaultValue="United Kingdom"
                  render={({ onChange, onBlur, value }) => (
                    <CountrySelector
                      onChange={onChange}
                      selected={value}
                      pageType={pageType}
                    />
                  )}
                />
              </OptionsContainer>
            </div>

            <SubmitButtonContainer>
              <div className="errors-container">
                {(errors.fName || errors.lName || errors.email) && (
                  <p>Please complete the missing information</p>
                )}
              </div>

              <div className="top-line-container">
                <button type="submit" className="submit-button" tabIndex="0">
                  Submit
                </button>
              </div>
            </SubmitButtonContainer>
          </form>
        </>
      )}

      {!isFormVisible && (
        <div className="thank-you-message" id="thank_you_message">
          <div
            dangerouslySetInnerHTML={{
              __html:
                data.prismicHomepage.data.newsletter_confirmation_text.html,
            }}
          />
        </div>
      )}
    </EnquiryFormContainer>
  );
};
