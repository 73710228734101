import React, { useState, useEffect } from "react";
import styled from "styled-components";

// Icons
import { Logo } from "../icons/logo";
import { OpenMenuIcon } from "../icons/menu-icons";

const HeaderContainer = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;

  & .top-menu-navigation {
    position: relative;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;

    z-index: 2;

    & button {
      & svg {
        transition: 250ms transform ease;
      }
    }
  }

  &.closed {
    & nav {
      transform: translateY(-100%);
    }
  }

  &.open {
    & nav {
      transform: translateY(0);
    }

    & button {
      & svg {
        transform: rotate(45deg);
      }
    }
  }

  & nav {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    padding: 90px 20px 20px 20px;
    transition: 300ms transform ease-in;
    z-index: 1;

    & ol {
      display: block;

      & li {
        margin: 0 0 10px 0;

        & a {
          font-size: 15px;
          line-height: 19px;
          letter-spacing: 0.075em;

          &.current {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 4px;
          }

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              text-decoration: underline;
              text-decoration-thickness: 1px;
              text-underline-offset: 4px;
            }
          }
        }
      }
    }
  }
`;

export const MobileMenu = ({ links, location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  return (
    <HeaderContainer className={isMenuOpen ? `open` : `closed`}>
      <div className="top-menu-navigation">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <OpenMenuIcon />
        </button>

        <Logo />
      </div>

      <nav>
        <ol>{links}</ol>
      </nav>
    </HeaderContainer>
  );
};
