import React from "react";
import styled from "styled-components";

const LinkArrowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;

  width: 112px;

  & .arrowhead {
    display: none;
  }

  & .stem {
    position: relative;

    width: 112px;
    transform-origin: left;
    transition: 250ms width ease;
    border-bottom: 1px solid ${props => props.fill};
  }

  & .arrow {
    position: absolute;
    top: -3px;
    right: 0;

    width: 12px;
    height: 10px;

    &::after,
    &::before {
      content: "";
      position: absolute;
      width: 12px;
      height: 1px;
      right: -3px;
    }

    &::after {
      top: -1px;
      transform: rotate(45deg);
      background-color: ${props => props.fill};
    }

    &::before {
      top: 7px;
      transform: rotate(-45deg);
      background-color: ${props => props.fill};
    }
  }

  /* & .arrowhead {
    position: absolute;
    right: -2px;
    top: -8.5px;
  } */

  @media (max-width: 1279px) {
    width: 68px;

    & .stem {
      width: 68px;
      border-width: 0.05rem;
    }

    & .arrow {
      top: -2.5px;

      &::after,
      &::before {
        height: 0.04rem;
        width: 8px;
        right: -1px;
      }

      &::after {
        top: 0px;
      }

      &::before {
        top: 6px;
      }
    }
  }

  @media (max-width: 768px) {
    & .stem {
      display: none;
    }

    & .arrowhead {
      display: block;
    }

    /* & .arrow {
      top: -3px;

      &::after,
      &::before {
        height: 0.05rem;
        width: 8px;
        right: -2px;
      }

      &::after {
        top: 0px;
      }

      &::before {
        top: 5px;
      }
    } */
  }
`;

export const LinkArrow = ({ fill }) => (
  <LinkArrowContainer fill={fill} className="link-arrow-container">
    <div className="stem">
      <div className="arrow" />
    </div>

    <svg
      width="68"
      height="11"
      viewBox="0 0 68 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="arrowhead"
    >
      <path
        d="M61.2572 0L60.7157 0.441742L66.5355 5.18891H33.2678H0V5.81109H66.5355L60.7157 10.5645L61.2572 11L68 5.5L61.2572 0Z"
        fill={fill}
      />
    </svg>
  </LinkArrowContainer>
);
