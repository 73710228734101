import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { useIntersection } from "react-use";
import { PrismicRichText } from "@prismicio/react";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-setup";
import { LinkArrow } from "../components/icons/link-arrow";

const Page = styled.div`
  padding: 160px 30px;

  @media (max-width: 1279px) {
    padding: 125px 20px 150px 20px;
  }

  & .articles-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 150px;

    & .single-article-container {
      & a {
        display: block;
        width: fit-content;
        margin: 0 auto;
      }

      & h2 {
        font-size: 35px;
        line-height: 45px;
        text-align: center;
        letter-spacing: 0.075em;
        text-transform: uppercase;

        margin: 0;
      }

      & .container {
        & p {
          font-size: 35px;
          line-height: 45px;
          letter-spacing: 0.075em;

          margin: 0;
        }
      }

      & .website-title {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        margin: 0 0 25px 0;

        & p {
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.075em;

          margin: 0 20px 0 0;
        }
      }

      & .article-title {
        max-width: 360px;
        margin: 0 auto;
      }

      & p {
        &.article-date {
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.075em;

          margin: 22px 0 0 0;
          text-transform: uppercase;
        }
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          & .stem {
            width: 80px;

            @media (max-width: 1279px) {
              width: 40px;
            }
          }
        }
      }
    }
  }

  /* @media (max-width: 1279px) {
    & .articles-container {
      grid-template-columns: 1fr 1fr;

      & .single-article-container {
        margin: 0 0 50px 0;
      }
    }
  } */

  @media (max-width: 1279px) {
    & .articles-container {
      grid-row-gap: 50px;

      & .single-article-container {
        margin: 0 0 43px 0;

        &:last-of-type {
          margin: 0;
        }

        & h2 {
          font-size: 22px;
          line-height: 29px;
          letter-spacing: 0.075em;
          text-align: center;
          letter-spacing: 0.075em;
          text-transform: uppercase;

          margin: 0;
        }

        & .article-title {
          max-width: 220px;
        }

        & .website-title {
          margin: 0 0 10px 0;

          & p {
            font-size: 11.5px;
            line-height: 15px;

            margin: 0 8px 0 0;
          }
        }

        & p {
          &.article-date {
            font-size: 11.5px;
            line-height: 15px;

            margin: 8px 0 0 0;
          }
        }
      }
    }
  }

  @media (max-width: 920px) {
    & .articles-container {
      display: block;
      grid-template-columns: unset;
      grid-column-gap: unset;
      grid-row-gap: unset;
    }
  }

  @media (max-width: 768px) {
  }
`;

const News = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    setPageSetup({
      pageType: `news`,
    });
  }, []);

  const articles = data.prismicNews.data.body.map((article, index) => (
    <div
      className="single-article-container"
      key={`single_article_container_${index}`}
    >
      <a
        href={article.primary.link.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className="article-content center-text">
          <div className="website-title uppercase">
            <PrismicRichText field={article.primary.website.richText} />
            <LinkArrow fill={`#0043EA`} />
          </div>

          <div className="article-title glossy-display">
            <PrismicRichText field={article.primary.text.richText} />
          </div>
          <p className="article-date">{article.primary.date}</p>
        </div>
      </a>
    </div>
  ));

  return (
    <Page>
      <div className="articles-container">{articles}</div>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicNews {
      _previewable
      data {
        body {
          ... on PrismicNewsDataBodyArticle {
            id
            slice_type
            primary {
              website {
                richText
              }
              text {
                richText
              }
              link {
                url
              }
              date(formatString: "DD MMMM YYYY")
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(News);
