import React, { useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { useLockBodyScroll, useToggle } from "react-use";

// Components
import { SignUp } from "./signup-form";

const FooterNewsletterContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* min-height: 235px; */
  padding: 40px 30px 30px 30px;

  &.page-type-home {
    background-color: #fdebe8;
    color: #0043ea;
  }

  &.page-type-about {
    background-color: #fdebe8;
  }

  &.page-type-work {
    background-color: #dcede9;
  }

  &.page-type-fund {
    background-color: #e4edf5;
    color: #0043ea;

    & .field {
      /* border-bottom: 1px solid #f53b07; */
      border-bottom: 1px solid #0043ea;

      & input {
        color: #0043ea;

        ::-webkit-input-placeholder {
          color: #0043ea;
        }
        ::-moz-placeholder {
          color: #0043ea;
        }
        :-ms-input-placeholder {
          color: #0043ea;
        }
        :-moz-placeholder {
          color: #0043ea;
        }
      }
    }

    & .dropdown-container {
      & .dropdown-header {
        color: #0043ea;
      }
    }

    & .top-line-container {
      & button {
        /* border: 1px solid #f53b07; */
        border: 1px solid #0043ea;

        color: #0043ea;

        @media (hover: hover) and (pointer: fine) {
          &:hover,
          &:focus,
          &:focus-within {
            color: #ebe6fd;
            background-color: #f53b07;
          }
        }
      }
    }
  }

  &.page-type-news {
    background-color: #ebe6fd;
  }

  &.page-type-shop,
  &.page-type-product {
    background-color: #faf7eb;

    & .field {
      border-bottom: 1px solid #0043ea;

      /* border-bottom: 1px solid #f53b07; */

      & input {
        color: #0043ea;

        ::-webkit-input-placeholder {
          color: #0043ea;
        }
        ::-moz-placeholder {
          color: #0043ea;
        }
        :-ms-input-placeholder {
          color: #0043ea;
        }
        :-moz-placeholder {
          color: #0043ea;
        }
      }
    }

    & .dropdown-container {
      & .dropdown-header {
        color: #0043ea;
      }
    }

    & .top-line-container {
      & button {
        /* border: 1px solid #f53b07; */
        border: 1px solid #0043ea;

        color: #0043ea;

        @media (hover: hover) and (pointer: fine) {
          &:hover,
          &:focus,
          &:focus-within {
            color: #ebe6fd;
            background-color: #f53b07;
          }
        }
      }
    }
  }

  &.page-type-cart {
    background-color: #e4edf5;
  }

  &.page-type-contact {
    background-color: #dcede9;
  }

  &.page-type-privacy-policy {
    background-color: #fdebe8;
  }

  transition: 250ms transform ease;

  &.visible {
    transform: translateY(0);
    z-index: 300;
    pointer-events: all;
    visibility: visible;
  }

  &.hidden {
    transform: translateY(100%);
    z-index: 1;
    pointer-events: none;
    visibility: hidden;
  }

  & .flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & .column {
      width: 100%;

      &.left {
        max-width: 485px;

        & h3 {
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.075em;

          @media (max-width: 768px) {
            font-size: 10px;
            line-height: 13px;
          }
        }

        & p {
          font-size: 18px;
          line-height: 23px;
          letter-spacing: 0.025em;

          @media (max-width: 1279px) {
            font-size: 14px;
            line-height: 18px;
          }

          @media (max-width: 768px) {
            font-size: 11px;
            line-height: 14px;
          }
        }

        @media (max-width: 1100px) {
          max-width: 50%;
          padding: 0 20px 0 0;
        }

        @media (max-width: 1279px) {
          margin: 0 0 30px 0;
        }
      }

      &.right {
        max-width: 560px;

        & ul#downshift-1-menu {
          max-height: 75px;
        }

        @media (max-width: 1100px) {
          max-width: 50%;
          padding: 0 0 0 20px;
        }
      }
    }
  }

  @media (max-width: 1279px) {
    padding: 20px;
  }

  @media (max-width: 768px) {
    & .flex {
      & .column {
        &.left,
        &.right {
          max-width: 100%;
          padding: 0;
        }
      }
    }
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  cursor: pointer;

  &.visible {
    z-index: 100;

    pointer-events: all;
    visibility: visible;
  }

  &.hidden {
    z-index: -100;
    pointer-events: none;
    visibility: hidden;
  }
`;

export const FooterNewsletter = ({
  isOpen,
  setIsNewsletterVisible,
  pageType,
}) => {
  // Scroll lock
  const [locked, toggleLocked] = useToggle(false);
  useLockBodyScroll(locked);

  useEffect(() => {
    if (isOpen) {
      toggleLocked(true);
    } else {
      toggleLocked(false);
    }
  }, [isOpen]);

  const data = useStaticQuery(graphql`
    query {
      prismicMailingList {
        data {
          title {
            richText
          }
          thank_you_text {
            richText
          }
          text {
            richText
          }
        }
      }
    }
  `);

  return (
    <>
      <FooterNewsletterContainer
        className={`page-type-${pageType} ${isOpen ? `visible` : `hidden`}`}
      >
        <div className="flex">
          <div className="column left">
            <PrismicRichText
              field={data.prismicMailingList.data.title.richText}
            />
            <PrismicRichText
              field={data.prismicMailingList.data.text.richText}
            />
          </div>
          <div className="column right">
            <SignUp pageType={pageType} isNewsletter={true} />
          </div>
        </div>
      </FooterNewsletterContainer>

      <Background
        className={isOpen ? `visible` : `hidden`}
        onClick={() => {
          setIsNewsletterVisible(false);
          toggleLocked(false);
        }}
      />
    </>
  );
};
