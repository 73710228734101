import React, { useState, useEffect, useContext, useRef } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage } from "gatsby-plugin-image";
import smoothscroll from "smoothscroll-polyfill";

// Previews
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Hooks
import { useMediaQuery } from "../components/hooks/useMediaQuery";

// Context
import { PageSetup } from "../components/context/page-setup";

// Components
import { Profile } from "../components/about/profile";

const Page = styled.div`
  padding: 160px 0 210px 0;

  @media (max-width: 1279px) {
    padding: 125px 0 120px 0;
  }

  & section {
    margin: 0 auto 150px auto;

    & .section-title {
      margin: 0 0 50px 0;
    }

    &:last-of-type {
      margin: 0 auto;
    }
  }

  & .founders-statement-container {
    padding: 0 30px;

    & .text-container {
      max-width: 1150px;
      margin: 0 auto;

      & p {
        display: block;
        margin: 45px 0;

        &:first-of-type,
        &:last-of-type {
          max-width: 915px;
          margin: 45px auto;
        }

        &:nth-of-type(2) {
          max-width: 560px;
          margin: 45px 0 45px auto;
        }

        &:nth-of-type(3) {
          max-width: 680px;
          margin: 45px 0;
        }
      }
    }
  }

  & .our-team-container {
    & .team-container {
      & .single-team-member {
        position: relative;

        max-width: 1150px;
        margin: 0 auto;

        padding: 0 30px;

        & .profile-container {
          position: relative;

          z-index: 10;
        }

        & .team-member-text {
          margin: 0 0 20px 0;
          cursor: none;
        }

        & button {
          padding: 5px 0;
          display: block;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              & .stem {
                width: 80px;

                @media (max-width: 1279px) {
                  width: 40px;
                }
              }
            }
          }
        }

        &.layout-1 {
          & .inner-single-team-member-container {
            max-width: 680px;
            margin: 0 0 60px 115px;
          }
        }

        &.layout-2 {
          & .inner-single-team-member-container {
            max-width: 560px;
            margin: 0 0 60px auto;
          }
        }

        &.layout-3 {
          & .inner-single-team-member-container {
            max-width: 680px;
            margin: 0 0 60px 0;
          }
        }

        &.layout-4 {
          & .inner-single-team-member-container {
            max-width: 680px;
            margin: 0 115px 60px auto;
          }
        }

        & p {
          margin: 0;
        }
      }
    }

    & .full-team-member-information {
      cursor: pointer;

      transition: 350ms all linear;
      display: none;
      /* overflow: hidden; */
      /* max-height: 0; */

      &.open {
        display: block;
        /* max-height: 100vh; */
        background-color: #fdebe8;
        padding: 60px 30px;
        margin: 0 0 60px 0;
      }

      & .full-team-member-text {
        max-width: 680px;
        margin: 0 auto;

        padding: 0 0 100px 0;

        & p {
          &:first-of-type {
            margin-top: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      & .question-answer-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 50px;

        max-width: 1150px;
        margin: 0 auto;

        & h3 {
          font-size: 18px;
          line-height: 20px;
          letter-spacing: 0.075em;

          text-transform: uppercase;
          text-align: center;
        }

        & p {
          text-align: center;
          margin: 0;
        }
      }
    }
  }

  & .partners-container {
    max-width: 1150px;
    padding: 0 30px;

    & .partners-text-container {
      max-width: 915px;
      margin: 0 auto 70px auto;
    }

    & .partner-logos-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-column-gap: 120px;
      grid-row-gap: 80px;

      & .single-partner-container {
        & img {
          object-fit: contain !important;

          @media (max-width: 1279px) {
            max-width: 120px;
            max-height: 50px;
            object-position: center center;
            top: 50% !important;
            left: 50% !important;
            transform: translate(-50%, -50%) !important;
          }
        }
      }
    }
  }

  @media (max-width: 1279px) {
    & section {
      margin: 0 auto 60px auto;

      & .section-title {
        margin: 0 0 25px 0;
      }
    }

    & .founders-statement-container {
      padding: 0 20px;

      & .text-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 20px;
        max-width: 950px;

        & p {
          &:first-of-type {
            max-width: 595px;
            /* max-width: 915px; */
            grid-column: 1 / 7;
            margin: 0 0 25px 0;
          }

          &:nth-of-type(2) {
            max-width: 560px;
            grid-column: 2 / 7;
            margin: 0 0 25px 0;
          }

          &:nth-of-type(3) {
            max-width: 680px;
            grid-column: 1 / 5;
            margin: 0 0 25px 0;
          }

          &:last-of-type {
            max-width: 565px;
            grid-column: 3 / 7;
            /* margin: 0; */
            margin: 0 0 25px auto;
          }
        }
      }
    }

    & .our-team-container {
      & .team-container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-column-gap: 20px;

        & .single-team-member {
          padding: 0 20px;

          & .team-member-text {
            margin: 0 0 8px 0;
          }

          &.layout-1 {
            grid-column: 1 / 6;

            & .inner-single-team-member-container {
              max-width: 510px;
              margin: 0 0 25px 0;
            }
          }

          &.layout-2 {
            grid-column: 2 / 7;

            & .inner-single-team-member-container {
              max-width: 450px;
              margin: 0 0 25px auto;
            }
          }

          &.layout-3 {
            grid-column: 1 / 5;

            & .inner-single-team-member-container {
              max-width: 510px;
              margin: 0 0 25px 0;
            }
          }

          &.layout-4 {
            grid-column: 2 / 7;

            & .inner-single-team-member-container {
              max-width: 450px;
              margin: 0 0 25px auto;
            }
          }

          &:last-of-type {
            & .inner-single-team-member-container {
              margin: 0;
            }
          }
        }

        & .full-team-member-information {
          grid-column: 1 / 7;
          padding: 30px 20px;
          margin: 0 0 30px 0;

          & .full-team-member-text {
            max-width: 580px;
            padding: 0 0 30px 0;
          }

          & .question-answer-container {
            display: block;
            grid-template-columns: unset;
            grid-column-gap: unset;
            grid-row-gap: unset;

            & .single-question-answer-container {
              max-width: 300px;
              margin: 0 auto 20px auto;

              &:last-of-type {
                margin: 0 auto;
              }

              & h3 {
                font-size: 11.5px;
                line-height: 15px;
              }
            }
          }
        }
      }
    }

    & .partners-container {
      max-width: 950px;
      padding: 0 20px;

      & .partners-text-container {
        max-width: 595px;
      }
    }
  }

  @media (max-width: 768px) {
    & .partners-container {
      & .partners-text-container {
        margin: 0 auto 25px auto;
      }

      & .partner-logos-container {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 30px;
      }
    }
  }
`;

const About = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);
  const [openPersonId, setOpenPersonId] = useState(null);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 1280px)");

  useEffect(() => {
    setPageSetup({
      pageType: `about`,
    });
  }, []);

  useEffect(() => {
    if (openPersonId !== null && typeof window !== `undefined`) {
      const yOffset = -80;
      const element = document.getElementById(`id-${openPersonId}`);
      const y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, [openPersonId]);

  const team = data.prismicAbout.data.body.map((person, index) => {
    const questionAnswers = person.items.map((item, innerIndex) => (
      <div
        className="single-question-answer-container"
        key={`single_qa_${innerIndex}_${index}_${person.id}`}
      >
        <PrismicRichText field={item.question.richText} />
        <PrismicRichText field={item.answer.richText} />
      </div>
    ));

    return (
      <React.Fragment
        key={`single_team_member_container_${index}_${person.id}`}
      >
        <div className={`single-team-member layout-${index + 1}`}>
          <Profile
            person={person}
            setOpenPersonId={setOpenPersonId}
            openPersonId={openPersonId}
          />
        </div>

        <div
          className={`full-team-member-information ${
            openPersonId === person.id ? `open` : `closed`
          }`}
          onClick={() => setOpenPersonId(null)}
        >
          {person.primary.full_text.text.length >= 2 && (
            <div className="full-team-member-text">
              <PrismicRichText field={person.primary.full_text.richText} />
            </div>
          )}

          <div className="question-answer-container">{questionAnswers}</div>
        </div>
      </React.Fragment>
    );
  });

  const partners = data.prismicAbout.data.partner.map((partner, index) => (
    <div
      className="single-partner-container"
      key={`single_partner_container_${index}`}
    >
      <a
        href={partner.partner_website.url}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={
          partner.partner_logo.alt !== null
            ? partner.partner_logo.alt
            : `Partner website`
        }
      >
        <GatsbyImage
          image={partner.partner_logo.gatsbyImageData}
          alt={
            partner.partner_logo.alt !== null
              ? partner.partner_logo.alt
              : `Partner logo`
          }
        />
      </a>
    </div>
  ));

  return (
    <Page>
      <section className="founders-statement-container">
        <h1 className="uppercase center-text section-title glossy-display">
          Founders Statement
        </h1>

        <div className="text-container">
          <PrismicRichText
            field={data.prismicAbout.data.founders_statement.richText}
          />
        </div>
      </section>

      <section className="our-team-container">
        <h1 className="uppercase center-text section-title glossy-display">
          Our Team
        </h1>

        <div className="team-container">{team}</div>
      </section>

      <section className="partners-container">
        <h1 className="uppercase center-text section-title glossy-display">
          Partners
        </h1>

        <div className="partners-inner-container">
          <div className="partners-text-container">
            <PrismicRichText
              field={data.prismicAbout.data.partners_text.richText}
            />
          </div>

          <div className="partner-logos-container">{partners}</div>
        </div>
      </section>
    </Page>
  );
};

export const query = graphql`
  query {
    prismicAbout {
      _previewable
      data {
        founders_statement {
          richText
        }
        body {
          ... on PrismicAboutDataBodyPerson {
            id
            slice_type
            primary {
              name {
                richText
                text
              }
              excerpt_text {
                richText
              }
              full_text {
                richText
                text
              }
              image {
                gatsbyImageData
                alt
              }
            }
            items {
              question {
                richText
              }
              answer {
                richText
              }
            }
          }
        }
        partners_text {
          richText
        }
        partner {
          partner_website {
            url
          }
          partner_logo {
            alt
            gatsbyImageData(height: 90, width: 215, layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;

export default withPrismicPreviewResolver(About);
