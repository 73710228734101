import React, { useContext, useState, useEffect } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
// import LazyLoad from "react-lazyload";

// Utils
// import { getMetafield } from "../utils/get-metafield";

const SingleProductContainer = styled.div`
  & .product-image {
  }

  & .product-title {
    margin: 10px 0 0 0;

    & h3,
    & p {
      font-size: 13px;
      line-height: 19px;
      letter-spacing: 0.02em;
      margin: 0;
    }

    & .from-text {
      font-size: 11px;
      letter-spacing: 0.01em;
    }
  }
`;

export const SingleProduct = ({ product, index }) => {
  return (
    <SingleProductContainer className="single-product">
      <Link to={`/shop/${product.handle}/`}>
        {product.featuredImage !== null && (
          <GatsbyImage
            image={product.featuredImage.gatsbyImageData}
            alt={product.title}
            draggable="false"
          />
        )}
      </Link>

      <Link to={`/shop/${product.handle}/`}>
        <div className="product-title">
          <h3 className="uppercase">
            {product.title}
            <span className="spacer"> — </span>
            <span className="price">
              {new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: product.priceRangeV2.minVariantPrice.currencyCode,
              }).format(product.priceRangeV2.minVariantPrice.amount)}
            </span>
          </h3>
        </div>
      </Link>
    </SingleProductContainer>
  );
};
