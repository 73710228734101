import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";

// Context
import { PageSetup } from "../components/context/page-setup";

// Icons
import { Logo } from "../components/icons/logo";
import { Text } from "../components/icons/text";

// Components
import { SignUp } from "../components/forms/signup-form";

const Page = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  padding: 250px 30px 200px 30px;

  @media (max-width: 1279px) {
    padding: 125px 20px 145px 20px;
  }
`;

const TextContainer = styled.div`
  width: 100%;

  & .text {
    max-width: 915px;
    margin: 0 auto;

    & p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  & strong {
    font-size: 15px;
    line-height: 27px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.075em;
    text-transform: uppercase;

    @media (max-width: 1279px) {
      font-size: 11.5px;
      line-height: 15px;
    }
  }
`;

const PrivacyPolicy = ({ data }) => {
  const [pageSetup, setPageSetup] = useContext(PageSetup);

  useEffect(() => {
    setPageSetup({
      pageType: `privacy-policy`,
    });
  }, []);

  return (
    <Page>
      <TextContainer>
        <div
          className="text"
          dangerouslySetInnerHTML={{
            __html: data.prismicPrivacyPolicy.data.text.html,
          }}
        />
      </TextContainer>
    </Page>
  );
};

export default withPrismicPreviewResolver(PrivacyPolicy);

export const query = graphql`
  {
    prismicPrivacyPolicy {
      _previewable
      data {
        text {
          html
        }
      }
    }
  }
`;
